'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

(function () {
  'use strict';

  angular.module('app').directive('segcomForm', segcomForm);

  function segcomForm() {
    return {
      replace: true,
      templateUrl: 'app/clients/components/segcomForm.html',
      controller: segcomFormController,
      controllerAs: '$ctrl',
      bindToController: {
        model: '='
      }
    };

    function segcomFormController($scope, $timeout) {
      var _this = this;

      this.checkbox = {};

      $timeout(function () {
        for (var key in _this.model) {
          if (_this.model.hasOwnProperty(key)) {
            _this.checkbox[key] = true;
          }
        }
      });

      $scope.$watch('$ctrl.checkbox', function (checkbox) {
        if (Object.keys(checkbox).length) {
          var model = {};

          for (var key in checkbox) {
            if (checkbox.hasOwnProperty(key) && checkbox[key]) {
              model[key] = _this.model[key] || true;
            }
          }
          _this.model = _extends({}, model);
        }
      }, true);
    }
  }
})();