'use strict';

(function () {
  'use strict';

  angular.module('app').directive('onlyDigits', onlyDigits);
  function onlyDigits() {
    return {
      require: 'ngModel',
      restrict: 'A',
      link: function link(scope, element, attr, ctrl) {
        element.attr('pattern', '[0-9]*');
        var inputValue = function inputValue(val) {
          if (val) {
            var digits = parseInt(val, 10);

            if (digits !== val) {
              ctrl.$setViewValue(digits);
              ctrl.$render();
            }
            return digits;
          }
          return null;
        };

        ctrl.$parsers.push(inputValue);
      }
    };
  }
})();