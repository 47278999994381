'use strict';

(function () {
    'use strict';

    angular.module('app').factory('ModelBuilder', function () {
        'use strict';

        return {
            buildModelList: function buildModelList(list, configObj, params) {
                var modelList = [];

                angular.forEach(list, function (item) {
                    var model = {};

                    for (var key in configObj) {
                        if (configObj.hasOwnProperty(key) && angular.isFunction(configObj[key])) {
                            model[key] = configObj[key](item, params);
                        }
                    }

                    modelList.push(model);
                });
                return modelList;
            }
        };
    });
})();