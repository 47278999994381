'use strict';

(function () {
  'use strict';

  angular.module('app').directive('addressList', addressList);

  function addressList() {
    return {
      replace: true,
      templateUrl: 'app/clients/components/addressList.html',
      controller: addressListController,
      controllerAs: '$ctrl',
      bindToController: {
        list: '=',
        remove: '&',
        disabled: '@'
      }
    };

    function addressListController() {
      var _this = this;

      this.removeItem = function (item) {
        return _this.remove({ item: item, list: 'addres' });
      };
    }
  }
})();