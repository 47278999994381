'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* global utils:true */
(function () {
  'use strict';

  angular.module('app').factory('BookModel', function () {
    'use strict';

    return {
      MaterialCode: function MaterialCode(record) {
        return record.Master.MaterialCode;
      },
      MaterialCodOld: function MaterialCodOld(record) {
        return record.Master.MaterialCodOld;
      },
      NomeMercado: function NomeMercado(record) {
        return record.Master.NomeMercado;
      },
      DescMat: function DescMat(record) {
        return record.Master.DescMat;
      },
      DescCor: function DescCor(record) {
        return record.Master.DescCor;
      },
      Disp: function Disp(record) {
        return record.Master.EstoqueDisp;
      },
      NcmCode: function NcmCode(record) {
        return record.Master.NcmCode;
      },
      PercIpi: function PercIpi(record) {
        return record.Master.PercIpi;
      },
      PntCode: function PntCode(record) {
        return record.Master.Pnt;
      },
      ImgEtq: function ImgEtq(record) {
        return record.Master.CodeImgEtq;
      },
      Gramatura: function Gramatura(record) {
        return record.Master.Gramatura;
      },
      GramaturaM2: function GramaturaM2(record) {
        return record.Master.GramaturaM2;
      },
      LargTotal: function LargTotal(record) {
        return record.Master.LargTotal;
      },
      GroupMaterial: function GroupMaterial(record) {
        return record.Master.GroupMaterial;
      },
      Ipad: function Ipad(record) {
        return record.Master.Ipad;
      },
      Composition: function Composition(record) {
        return record.Master.Composition;
      },
      Similaridade: function Similaridade(record) {
        return record.Master.Similaridade;
      },
      UnidMed: function UnidMed(record) {
        return record.Master.UnidMed;
      },
      QtdeMultiplo: function QtdeMultiplo(record) {
        return record.Master.QtdeMultiplo;
      },
      CorteMin: function CorteMin(record) {
        return record.Master.CorteMin;
      },
      Rendimento: function Rendimento(record) {
        return record.Master.Rendimento;
      },
      TpStockCode: function TpStockCode(record, params) {
        return params.stockCode;
      },
      LargUtil: function LargUtil(record) {
        return (+record.Master.LargUtil).toFixed(2);
      },
      LargRap: function LargRap(record) {
        return (record.Master.LargRap || 0) + '';
      },
      CompRap: function CompRap(record) {
        return (record.Master.CompRap || 0) + ' cm';
      },
      BookMat: function BookMat(record) {
        return record.Master.EbooksCodePage || "";
      } /*record.Master.EbookCode + '/' + (record.Master.EbookPage || 0)*/
      , Ounce: function Ounce(record) {
        return (+record.Master.Gramatura / 34).toFixed(2) + ' oz';
      },
      Oncas: function Oncas(record) {
        return (+record.Master.Oncas).toFixed(1) + ' oz';
      },
      Elasticidade: function Elasticidade(record) {
        return record.Master.Elasticidade;
      },
      Estabilidade: function Estabilidade(record) {
        return record.Master.Estabilidade1;
      },
      Acabamento: function Acabamento(record) {
        return record.Master.Acabamento;
      },
      protecaoUV: function protecaoUV(record) {
        return record.Master.Protecaouv ? 'FPS ' + record.Master.Protecaouv : '-';
      },
      Pilling: function Pilling(record) {
        return record.Master.Pilling;
      },
      Construcao: function Construcao(record) {
        return record.Master.Construcao;
      },
      MaterialZipper: function MaterialZipper(record) {
        return record.Master.MaterialZipper;
      },
      Certificados: function Certificados(record) {
        return record.Master.Certificados;
      },
      Modelagem: function Modelagem(record) {
        return {
          Top: {
            Ampla: record.Master.OversizeTop,
            Regular: record.Master.FitTop,
            Justa: record.Master.SlimTop
          },
          Bottom: {
            Ampla: record.Master.OversizeBt,
            Regular: record.Master.FitBt,
            Justa: record.Master.SlimBt
          }
        };
      },
      ParsedMult: function ParsedMult(record) {
        return utils.helpers.parseCurr(record.Master.QtdeMultiplo) + ' ' + record.Master.UnidMed;
      },
      ParsedMin: function ParsedMin(record) {
        return utils.helpers.parseCurr(record.Master.CorteMin) + ' ' + record.Master.UnidMed.toLowerCase();
      },
      Price: function Price(record) {

        var stock = utils.helpers.deepGet(record.Master, ['EstoqueNavig']);

        var priceSeg = utils.helpers.deepGet(record.Master, ['PrecoSegNavig', 'T100', '10' // canal de distribuição (10: comercio, 20: exclusivo)
        ]);
        var priceDefault = utils.helpers.deepGet(record.Master, ['PrecoNavig', 'T100', '10' // canal de distribuição (10: comercio, 20: exclusivo)
        ]);

        var prices = {};

        var existsMaterialTypeZipper = record.Master.MaterialZipper || null;
        var isMaterialZipperToFourDecimalPrice = existsMaterialTypeZipper ? 4 : 2;

        // Aqui é onde determina o preço/casas/centenas dos materiais
        Object.keys(stock).forEach(function (segmento) {
          // price[segmento] = priceSeg[segmento] || priceDefault;
          var price = priceSeg[segmento] || priceDefault;

          prices[segmento] = {
            Price: {
              BRL: utils.helpers.parseCurr(price.PrecoBrl, isMaterialZipperToFourDecimalPrice),
              USD: utils.helpers.parseCurr(price.PrecoUsd, isMaterialZipperToFourDecimalPrice)
            },
            Price90: {
              BRL: utils.helpers.parseCurr(price.PrecoBrl90, isMaterialZipperToFourDecimalPrice),
              USD: utils.helpers.parseCurr(price.PrecoUsd90, isMaterialZipperToFourDecimalPrice)
            },
            PrcMat: {
              PrecoBrl: utils.helpers.parseCurr(price.PrecoBrl, isMaterialZipperToFourDecimalPrice),
              PrecoUsd: utils.helpers.parseCurr(price.PrecoUsd, isMaterialZipperToFourDecimalPrice),
              PrecoBrl90: utils.helpers.parseCurr(price.PrecoBrl90, isMaterialZipperToFourDecimalPrice),
              PrecoUsd90: utils.helpers.parseCurr(price.PrecoUsd90, isMaterialZipperToFourDecimalPrice)
            }
          };
        });

        return prices;
      },
      RGB: function RGB(record) {
        var color = record.Master.RGB;
        if (!color) return;
        return "rgb(" + [color.R, color.G, color.B].join(",") + ")";
      },
      IsPreview: function IsPreview(record, params) {
        // return params.stockCode === 'V';
        return !!record.Master.IsPreview;
      },
      tonalidadeKeys: function tonalidadeKeys(record, params) {
        var stockCode = params.stockCode;

        if (params.stockCode === 'V' || params.stockCode === 'C') {
          stockCode = 'N';
        }

        var segmentos = Object.keys(record.Master.EstoqueNavig).filter(function (key) {
          return key.charAt(0) === stockCode;
        }).sort();

        // console.log("45t5g5 >>> ", segmentos);

        return segmentos.map(function (segmento, index) {
          var tpStock = segmento.slice(0, 2);
          var tom = segmento.slice(2, 4);
          var nuance = segmento.slice(4);
          var tomNuance = tom + nuance;

          return { name: tom, value: index };
        });
      },
      Stock: function Stock(record, params) {
        var stockCode = params.stockCode;

        if (params.stockCode === 'C') {
          stockCode = 'N';
        }

        if (params.stockCode === 'V') {
          stockCode = ['N', 'P'];
        }

        var stock = utils.helpers.deepGet(record.Master, ['EstoqueNavig']);

        if (!stock) {
          console.log("bookModel NO STOCK FOUND", record);
          return {};
        }

        // console.log("bookModel EstoqueNavig stock", record, stock);

        // antes era centros, agora segmentos
        var segments = Object.keys(stock).filter(function (segmento) {
          var isStock;

          if (typeof stockCode !== "string") {
            isStock = stockCode.indexOf(segmento.charAt(0)) > -1;
          } else {
            isStock = segmento.charAt(0) === stockCode;
          }

          return isStock;
        }).sort();

        return segments.map(function (segmento, index) {
          var tone = segmento.slice(2, 4);
          var nuance = segmento.slice(4);

          var centers = Object.keys(stock[segmento]);

          centers.forEach(function (center) {
            var stockInfo = stock[segmento][center];
            // stockInfo.ListDisp = stockInfo.filter(x => x.Centro === center);
            stock[segmento][center] = _extends({}, stockInfo, {
              Tonalidade: tone,
              Nuance: nuance,
              TomNuance: tone + nuance,
              TomIndex: index
            });
          });

          return stock[segmento];
        });
      },
      PrecoPromo: function PrecoPromo(record) {
        return record.Master.PrecoPromoNavig;
      },
      Tipo: function Tipo(record) {
        return record.Master.Tipo;
      }
    };
  });
})();