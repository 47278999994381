'use strict';

(function () {
  'use strict';

  angular.module('app').directive('addressForm', addressForm);

  function addressForm() {
    return {
      scope: true,
      replace: true,
      transclude: true,
      templateUrl: 'app/clients/components/addressForm.html',
      controller: addressFormController,
      controllerAs: '$ctrl',
      bindToController: {
        model: '=',
        formCtrl: '<',
        title: '@',
        type: '@',
        disabled: '@'
      }
    };

    function addressFormController($timeout, dataService) {
      var _this3 = this;

      this.isInscEstAdrCliValid = false;

      this.$onInit = function () {
        if (this.model.InscEstAdrCli) {
          this.validateIE(this.model.InscEstAdrCli.replace(/[.-]/g, ""), this.model.UfCliAdr).then(function () {});
        }
      };

      this.validateIE = function (ie, uf) {
        var _this = this;

        return new Promise(function (resolve, reject) {
          dataService.postData('validate-ie', { ie: ie, uf: uf }).then(function (_ref) {
            var validate = _ref.validate,
                isValid = _ref.isValid,
                message = _ref.message;

            _this.isInscEstAdrCliValid = isValid;
            resolve(isValid);
          }).catch(function (error) {
            _this.isInscEstAdrCliValid = false;
            resolve(false);
          });
        });
      };

      this.countries = [{ name: 'Brasil', value: 'BR' }, { name: 'Bolivia', value: 'BO' }, { name: 'Canada', value: 'CA' }];

      this.checkEnter = function (event) {
        var _this2 = this;

        if (event.keyCode === 13) {
          this.validateIE(event.target.value.replace(/[.-]/g, ""), this.model.UfCliAdr).then(function (isValid) {
            if (isValid) _this2.model.InscEstAdrCli = event.target.value;
          });
        }
      };

      $timeout(function () {
        _this3.formName = 'vm.add' + _this3.type + 'Form';
      });
    }
  }
})();