"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  'use strict';

  var PendListController = function () {
    function PendListController($scope) {
      _classCallCheck(this, PendListController);

      var continuousScroll = {
        pendenciasShowLoader: function pendenciasShowLoader() {
          var listaPendenciasLoader = document.querySelector("div.pendencias-loader");
          listaPendenciasLoader.style.display = "block";
        },
        pendenciasHideLoader: function pendenciasHideLoader() {
          var listaPendenciasLoader = document.querySelector("div.pendencias-loader");
          listaPendenciasLoader.style.display = "none";
        }
      };

      $scope.$on('loader-lista-pendencias-enabled', continuousScroll.pendenciasShowLoader);
      $scope.$on('loader-lista-pendencias-disabled', continuousScroll.pendenciasHideLoader);
    }

    _createClass(PendListController, [{
      key: "selectPend",
      value: function selectPend(item) {
        this.onClick({ pend: item });
      }
    }]);

    return PendListController;
  }();

  PendListController.$inject = ["$scope"];

  var pendList = {
    templateUrl: 'app/home/components/pendencies.html',
    bindings: {
      open: '=',
      items: '=',
      onClick: '&'
    },
    controller: PendListController
  };

  var ptaxList = {
    templateUrl: 'app/home/components/ptax.html',
    bindings: {
      items: '='
    }
  };

  angular.module('homePage', []).component('pendList', pendList).component('ptaxList', ptaxList);
})();