'use strict';

/* eslint angular/window-service: 0 */
/* eslint angular/definedundefined: 0 */

Object.defineProperty(Object.prototype, 'hass', {
  value: function value(needle) {
    var obj = this || {};
    var needles = needle.split('.');

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = needles[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var prop = _step.value;

        if (!obj.hasOwnProperty(prop)) {
          return false;
        }
        obj = obj[prop];
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return true;
  }
});

String.prototype.removeAccents = function () {
  var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  var accentsOut = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  return this.split('').map(function (letter) {
    var accentIndex = accents.indexOf(letter);

    return accentIndex === -1 ? letter : accentsOut[accentIndex];
  }).join('');
};

String.prototype.capitalize = function () {
  return this.toLowerCase().replace(/(^|\s)([a-z])/g, function (m, p1, p2) {
    return p1 + p2.toUpperCase();
  });
};
String.prototype.replaceAll = function (find, replace) {
  return this.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};
String.prototype.insert = function (what, index) {
  return index > 0 ? this.replace(new RegExp('.{' + index + '}'), '$&' + what) : what + this;
};

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    enumerable: false,
    value: function value(obj) {
      var newArr = this.filter(function (el) {
        return el === obj;
      });

      return newArr.length > 0;
    }
  });
}

_.mixin({
  mergeBy: function mergeBy(arr, obj, idProp) {
    var index = _.findIndex(arr, function (elem) {
      return typeof elem[idProp] !== 'undefined' && elem[idProp] === obj[idProp];
    });

    if (index > -1) {
      _.extend(arr[index], obj);
    } else {
      arr.push(obj);
    }
    return arr;
  }
});

// CORDOVA;

window.addEventListener('load', function () {
  window.scrollTo(0, 0);
});

function hoverTouchUnstick() {
  if ('ontouchstart' in document.documentElement) {
    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = document.styleSheets[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var sheet = _step2.value;

        if (sheet && sheet.cssRules) {
          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = sheet.cssRules[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var rule = _step3.value;

              rule.selectorText = rule.selectorText ? rule.selectorText.replace(':hover', ':active') : rule.selectorText;
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }
      }
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }
  }
}

angular.module('app', ['ui.router', 'ui.utils.masks', 'loadingStatus', 'rzModule', 'ngDialog', 'ngAnimate', 'homePage', '720kb.datepicker', 'swipe', 'vs-repeat']);

// angular.module('app').run(function() {
//   FastClick.attach(document.body);
// });

var corReady = false;

angular.element(document).ready(function () {
  if (window.cordova) {
    console.log("Running in Cordova, will bootstrap AngularJS once 'deviceready' event fires.");
    document.addEventListener('deviceready', function () {
      corReady = true;
      console.log('Deviceready event has fired, bootstrapping AngularJS.', corReady);
      angular.bootstrap(document.body, ['app']);
      hoverTouchUnstick();
    }, false);
  } else {
    console.log('Running in browser, bootstrapping AngularJS now.');
    angular.bootstrap(document.body, ['app']);
  }
});