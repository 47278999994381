'use strict';

(function () {
  'use strict';

  angular.module('app').directive('inputQtd', inputQtd);
  inputQtd.$inject = ['localService', '$timeout'];

  function inputQtd(localService, $timeout) {
    return {
      link: link,
      require: 'ngModel',
      restrict: 'A'
    };

    function link(scope, element, attrs, ctrl) {
      var vm = scope.$ctrl;
      var material = scope.material;
      var oldModel = void 0;

      scope.$watch('$ctrl.warnings', function (warnings) {
        ctrl.$setValidity('cart', true);
        oldModel = ctrl.$modelValue;
        angular.forEach(warnings, function (warn) {
          if (warn.MaterialCode === material.MaterialCode && warn.TpStockCode === material.TpStockCode && material.Abgru === "") {
            ctrl.$setValidity('cart', false);
            $timeout(localService.openModal, 500, true, warn.MaterialCode + ': ' + warn.Error);
          }
        });
      });

      element.on('blur', function () {
        if (!ctrl.$modelValue) {
          ctrl.$setValidity('cart', false);
          return localService.openModal('Quantidade não pode ser igual a zero');
        }
        console.log('[Teste]', ctrl.$modelValue, oldModel);
        if (ctrl.$modelValue !== oldModel) {
          oldModel = ctrl.$modelValue;
          return vm.getTotal();
        }
        return false;
      });

      element.on('keydown keypress', function (_ref) {
        var target = _ref.target,
            which = _ref.which;
        return which === 13 ? target.blur() : false;
      });
    }
  }
})();