'use strict';

/*  UPLOAD */
(function () {
  'use strict';

  angular.module('app').controller('UploadController', UploadController);

  function UploadController($scope) {
    var vm = this;
    var vp = $scope.$parent.vm;

    vm.data = { desc: '' };
    vm.uploadFile = uploadFile;
    checkItem();

    function uploadFile(event) {
      event.preventDefault();
      vm.data.file.desc = vm.data.desc;
      return $scope.closeThisDialog(vm.data.file);
    }

    function checkItem() {
      $scope.$watch('vm.data.desc', function (desc) {
        if (!desc) {
          return false;
        }

        var item = _.findWhere(vp.files, { desc: desc }) || false;

        return $scope.formage.$setValidity('desc', !item);
      });
    }
  }
})();