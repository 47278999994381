'use strict';

(function () {
  'use strict';

  angular.module('app').directive('clientFavorite', clientFavorite);

  function clientFavorite(dataService, localService) {
    return {
      restrict: 'AE',
      scope: {
        model: '=ngModel'
      },
      link: link
    };

    function link(scope, element) {
      var action = void 0;

      element.bind('click', function () {
        scope.model.Favorito = !scope.model.Favorito;
        action = scope.model.Favorito ? 'Add' : 'Remove';
        scope.$apply();

        return dataService.postData(action + 'FavoriteCustomer', {
          CodeCli: scope.model.CodeCli
        }).then(function (res) {
          console.log(res);
        }, localService.errorHandler);
      });
    }
  }
})();