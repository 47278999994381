'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

(function () {
  'use strict';

  angular.module('app').directive('contactsForm', contactsForm);

  function contactsForm() {
    return {
      replace: true,
      transclude: true,
      templateUrl: 'app/clients/components/contactsForm.html',
      controller: contactsFormController,
      controllerAs: '$ctrl',
      bindToController: {
        contacts: '=',
        save: '&'
      }
    };

    function contactsFormController() {
      var _this = this;

      console.log('[contacts]');
      this.model = { CargoCli: '' };

      this.departaments = [{ value: '', name: 'Selecione' }, { value: '0001', name: 'Direção' }, { value: '0002', name: 'Compras' }, { value: '0003', name: 'Venda' }, { value: '0004', name: 'Organização' }, { value: '0005', name: 'Administração' }, { value: '0006', name: 'Produção' }, { value: '0007', name: 'Garant. Qualidade' }, { value: '0008', name: 'Secretaria' }, { value: '0009', name: 'Dpto. Financeiro' }, { value: '0010', name: 'Dpto. Jurídico' }, { value: 'Z001', name: 'Produto' }, { value: 'Z002', name: 'Estilo' }, { value: 'Z003', name: 'Transporte' }, { value: 'Z004', name: 'Sócio / Proprietário' }, { value: 'ZF24', name: 'Focus 24H' }, { value: 'ZBOL', name: 'Boleto' }, { value: 'ZNFE', name: 'Nota Fiscal Eletrônica' }];

      this.submitForm = function (event) {
        event.preventDefault();
        _this.model.EmailCli = _this.model.EmailCli.toLowerCase();
        _this.contacts.push(_extends({}, _this.model));
        _this.model = { CargoCli: '' };
        _this.form.$setPristine();
        return _this.save();
      };

      this.setNfe = function () {
        if (_this.model.NfeCli) {
          _this.model.CargoCli = 'ZNFE';
          _this.model.CargoDesc = 'Nota Fiscal Eletrônica';
        } else {
          _this.model.CargoCli = '';
          _this.model.CargoDesc = '';
        }
      };

      this.getDep = function (dep) {
        _this.model.CargoDesc = _.findWhere(_this.departaments, {
          value: dep
        }).name;
      };
    }
  }
})();