'use strict';

/* SERVIÇO PARA CHAMADA DAS FUNÇÕES DO SERVIDOR DA APLICAÇÃO */

/* global corReady:true */
(function () {
  'use strict';

  angular.module('app').service('dataService', dataService);
  dataService.$inject = ['$http', '$q', 'localService', 'cordovaService'];

  /* @ngInject */

  // ? 'http://ii3:1037/api/v1/'

  function dataService($http, $q, localService, cordovaService) {
    var envDomain;
    envDomain = 'https://webrep2.focustextil.com.br/api/v1/';
    // envDomain = 'http://localhost:2037/api/v1/'
    // 'http://vivo-dev-node.focustextil.loc.br:1037/api/v1/';
    //   envDomain = localService.env
    //     ? 'http://ii3:1037/api/v1/'
    //     : 'https://webrep.focustextil.com.br:443/api/v1/';

    var path = envDomain;

    var service = {
      postData: postData,
      loadData: loadData,
      postFile: postFile,
      loginData: loginData,
      valData: valData,
      baseUrl: baseUrl
    };

    var headers = void 0;

    return service;

    function postData(action, data) {
      var jsonData = void 0;
      var deferred = $q.defer();

      var cordovaFunctions = ['App', 'Alerts', 'Ptax', 'Pendencies', 'FinancyFactor', 'Ebook', 'Material', 'MaterialDetail', 'FavoriteMaterial', 'AddFavoriteMaterial', 'RemoveFavoriteMaterial', 'Customer', 'CustomerDetail', 'NewCustomer', 'FavoriteCustomer', 'AddFavoriteCustomer', 'RemoveFavoriteCustomer', 'SaveShoppingCart', 'OpenShoppingCart', 'PaymentCondition', 'SendShoppingCart', 'SendCopyOv', 'StockUpdate', 'Cart', 'Carts', 'Order', 'Orders', 'LogOff', 'ChangePassword', 'ForgotPassword', 'OrderStatus'];

      headers = headers || setHeader();

      if (corReady && cordovaFunctions.includes(action)) {
        return cordovaService.postFunc(action, data);
      }

      jsonData = angular.toJson(data);

      $http.post(path + action, jsonData, headers).then(function (res) {
        return res.data.Error ? deferred.reject(res.data) : deferred.resolve(res.data);
      }, function (err) {
        return deferred.reject({
          Error: 'Instabilidade na conexão. Por favor, tente novamente. ' + err.statusText
        });
      });
      return deferred.promise;
    }

    /**
     * [loadData]
     * @returns {Function} [Função de Carregar Pacotes]
     */
    function loadData() {
      return cordovaService.startFunc();
    }

    function transformRequest(data) {
      var fd = new FormData();

      angular.forEach(data, function (value, key) {
        if (key.includes('Upld')) {
          fd.append('Files', value);
        } else {
          fd.append(key, value);
        }
      });
      return fd;
    }

    /**
     * [postFile - Envia Formulário com Arquivos ]
     * @param  {String} action  [ Nome da Função no Serviço]
     * @param  {Object} data    [ Data ]
     * @returns {Promise} [Teste]
     */
    function postFile(action, data) {
      var deferred = $q.defer();
      var user = user || localService.getData('user');
      var request = {
        method: 'POST',
        url: path + action,
        data: data,
        headers: {
          'Content-Type': undefined,
          'x-access-token': user.Token
        },
        transformRequest: transformRequest
      };

      console.log('[SERVICE]', action);

      $http(request).then(function (res) {
        if (res.data.Error) {
          deferred.reject(res.data);
        } else {
          deferred.resolve(res.data);
        }
      }, function (error) {
        console.log(error);
        deferred.reject({
          Error: 'Instabilidade na conexão. Por favor, tente novamente.'
        });
      });
      return deferred.promise;
    }

    function loginData(data) {
      var deferred = $q.defer();
      var jsonData = angular.toJson(data);

      if (corReady) {
        return cordovaService.loginFunc(data);
      }

      $http.post(path + 'Login', jsonData).then(function (res) {
        if (res.data.Error) {
          deferred.reject(res.data);
        } else if (res.data && res.data.User && (res.data.User.Reps || res.data.User.Gestores)) {
          deferred.resolve(res.data); // gestor ou admv login
        } else if (res.data && res.data.User && res.data.User.Sistemas && res.data.User.Sistemas.indexOf('WebRep') == -1) {
          deferred.resolve(res.data);
        } else {
          localService.setData('user', res.data.User);
          setHeader(res.data.User);
          deferred.resolve(res.data);
        }
      }, function (error) {
        return deferred.reject(error);
      });
      return deferred.promise;
    }

    function valData(data) {
      var deferred = $q.defer();
      var jsonData = angular.toJson(data);

      if (corReady) {
        return cordovaService.validadeFunc(data);
      }

      $http.post(path + 'ValidateLogin', jsonData, headers).then(function (res) {
        var response = res.data;

        headers = response ? {
          headers: {
            'x-access-token': response.Token
          }
        } : {};
        deferred.resolve(response);
      }, function (error) {
        deferred.reject(error);
      });
      return deferred.promise;
    }

    function setHeader() {
      var user = localService.getData('user');

      headers = user ? {
        headers: {
          'x-access-token': user.Token
        }
      } : {};
      return headers;
    }

    function baseUrl(url) {
      return path + url;
    }
  }
})();