'use strict';

(function () {
  'use strict';

  angular.module('app').directive('customValidate', customValidate);
  customValidate.$inject = ['rulesService', 'localService', '$timeout'];

  function customValidate(rulesService, localService, $timeout) {
    return {
      link: link,
      require: 'ngModel',
      restrict: 'A'
    };

    function link(scope, element, attrs, ctrl) {
      var viewModel = void 0;
      var vm = scope.$ctrl;
      var isNumber = function isNumber(n) {
        return isFinite(n) && +n === n;
      };

      $timeout(onLoad, 500);

      function customValidator(ngModelValue, load) {
        var currency = vm.page.currency;

        var material = scope.material;
        var test = { Error: false };
        ngModelValue = parseFloat(("" + ngModelValue).replace(",", "."));
        var cleanModel = ngModelValue ? ngModelValue.toString().replace(/[^\d.-]/g, '') : 0;
        var parsedModel = parseFloat(ngModelValue);

        if (material.TpStockCode !== 'C') {
          if (vm.kart.Master.PayCond != "A007") {
            if (parsedModel) {
              test = rulesService.overPrice(material.Price[material.Segmento].PrcMat['Preco' + currency.capitalize() + '90'], parsedModel, vm.params.OVP);
            } else {
              // REGRA DESCARTADA PELO MARANI test = { Error: 'Campo não pode ser 0.' };
            }
          }
        }

        if (parseFloat(cleanModel) !== parsedModel) {
          test.Error = 'Valor inválido.';
        }

        if (test.Error) {
          $timeout(localService.openModal, 500, true, test.Error);
          ctrl.$render();
          return false;
        }

        if (!load) {
          vm.getTotal();
        }

        return true;
      }

      element.on('blur', function () {
        var validation = customValidator(ctrl.$modelValue);

        ctrl.$setValidity(attrs.customValidate, validation);
        scope.$apply();
      });

      element.on('focus', function (e) {
        e.target.setSelectionRange(0, 99999);
      });

      element.on('keydown keypress', function (_ref) {
        var target = _ref.target,
            which = _ref.which;
        return which === 13 ? target.blur() : false;
      });

      function onLoad() {
        var model = ctrl.$modelValue || 0;

        var validation = customValidator(model, true);

        viewModel = isNumber(model) ? model.toFixed(2) : model;

        ctrl.$setValidity(attrs.customValidate, validation);
        scope.$apply();
      }
    }
  }
})();