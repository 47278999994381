'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/* global utils:true */

(function () {
  'use strict';

  angular.module('app').controller('CartsDelvController', CartsDelvController);
  CartsDelvController.$inject = ['$scope', '$state', 'localService', 'dataService'];

  /* @ngInject */

  function CartsDelvController($scope, $state, localService, dataService) {
    var vm = this;
    var openModal = localService.openModal;
    var parseDate = utils.helpers.parseDate;

    var isEfocusCustomer = localService.getData('isEfocusCustomer');
    var undoRedispHandle = "{}";

    $scope.isEfocusCustomer = isEfocusCustomer;
    $scope.EditRedispFields = {};
    $scope.registerRedispDataVisible = false;
    $scope.updateRedispDataVisible = false;

    vm.RedispatchOk = true;
    vm.isRedispatchEdit = false;

    vm.sendKart = sendKart;
    vm.cartConfirmation = cartConfirmation;
    vm.backKart = backKart;
    vm.parseCurr = utils.helpers.parseCurr;
    vm.backToCarts = backToCarts;
    vm.getRedespachoData = getRedespachoData;
    vm.activateRedispCentro = activateRedispCentro;
    vm.changeRedispCentro = changeRedispCentro;
    vm.registerRedispData = registerRedispData;
    vm.updateRedispData = updateRedispData;
    vm.cancelUpdateRedispData = cancelUpdateRedispData;
    vm.changeDeliveryLocation = changeDeliveryLocation;

    vm.redespachoData = {};
    vm.redespachoModel = {};
    vm.redispModel = {};
    vm.RedispatchRequiredCenter = {};
    vm.RedispatchFilledCenter = {};
    vm.RedispCondition = [];

    var origins = {
      'T101': 'ES',
      'T103': 'SC',
      'T104': 'ES',
      'T105': 'PE',
      'T106': 'CE'
    };
    vm.origins = origins;
    vm.RedispatchRequired = false;

    vm.options = {};
    vm.page = {
      currency: 'BRL',
      deadline: 'Z013',
      date: '',
      delivery: '',
      total: 0,
      qaorder: ''
    };

    activate();

    function activate() {
      vm.kart = localService.getData('kart');
      vm.pages = localService.getData('pages');

      vm.page = _extends({}, vm.page, {
        currency: vm.kart.Master.CodeCurr,
        deadline: vm.kart.Master.PayCond
      });

      vm.kart.Master.CodeDelivLoc = vm.kart.Master.CodeDelivLoc || vm.kart.Master.CodeCli;

      //TO:DO set to get in Redisp902
      if (vm.kart.Master.Editavel) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = vm.kart.Master.Item902[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;

            vm.redispCenterSelected = item.Centro;
            getRedespachoData(item.CnpjRedisp, vm.redispCenterSelected);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }
      } else {
        try {
          setTimeout(function () {
            vm.redispCenterSelected = vm.kart.Master.Redisp902[0].Centro;
            getRedespachoData(vm.kart.Master.Redisp902[0].CnpjRedisp, vm.redispCenterSelected);
          }, 1300);
          /*
          for (let item of vm.kart.Master.Redisp902) {
            vm.redispCenterSelected = vm.kart.Master.Redisp902[0].Centro;
            getRedespachoData(vm.kart.Master.Redisp902[0].CnpjRedisp, vm.redispCenterSelected);
          }
          */
        } catch (e) {}
      }

      if (vm.kart.Master.CodeOv) {
        console.log('vai virar true...');
        vm.isRedispatchEdit = true;
      }

      console.log('[Activate Cars Delv]', vm.kart, vm.page);
      return cartOptions();
    }

    function cartOptions() {
      return dataService.postData('ShoppingCartOptions', { CodeCli: vm.kart.Master.CodeCli }).then(function (_ref) {
        var Results = _ref.Results;

        var CndistCli = Results.CndistCli;
        delete Results.CndistCli;

        _.each(Results, function (value, key) {
          vm.options[key] = key === 'Address' ? parseOptions(value, ['CodeCli', 'Razao01Cli']) : parseOptions(value);
        });

        vm.delivery = Results.Address;
        if (vm.kart.Master["Redispatch"] == "X") {
          //if (vm.kart.Master["Redispatch"] === "0") {
          vm.page.delivery = "X";
        } else {
          vm.page.delivery = "";
        }

        if ((!!vm.options.Address.length && !vm.kart.Master.CodeOv || vm.kart.Master.Editavel && vm.kart.Master.CodeCli != vm.kart.Master.CodeDelivLoc) && !(vm.kart.Master.TipoOrdem == 'ZVSR' || vm.kart.Master.TipoOrdem == 'ZVSF')) {
          localService.openModal('Este cliente possui mais de um Local de Entrega.');
        }

        var hasDIFAL = CndistCli === '0002';
        if (hasDIFAL) {
          localService.openModal('Cliente com incidência de DIFAL');
        }

        loadCartCenters(vm.kart);

        return vm.options;
      }, localService.errorHandler);
    }

    function changeDeliveryLocation() {
      loadCartCenters(vm.kart);
    }

    function updateCartData(cart) {
      localService.setData('kart', cart);
      vm.kart = cart;
    }

    function cancelUpdateRedispData(centro) {
      vm.redispModel = JSON.parse(undoRedispHandle);
      undoRedispHandle = "{}";
      $scope.updateRedispDataVisible = true;
      $scope.EditRedispFields[centro] = false;
      $scope.registerRedispDataVisible = false;
    }

    function enableEditRedisp(centro) {
      undoRedispHandle = JSON.stringify(vm.redispModel);
      $scope.updateRedispDataVisible = true;
      $scope.EditRedispFields[centro] = true;
      $scope.registerRedispDataVisible = false;
    }

    function onRedispSaved(centro, data) {
      setCentroFilled(centro, data);
      $scope.updateRedispDataVisible = true;
      $scope.EditRedispFields[centro] = false;
      $scope.registerRedispDataVisible = false;
    }

    function updateRedispData(centro) {
      var isSaving = $scope.EditRedispFields[centro];
      var formElement = vm.redform.$$element[0];
      if (isSaving) {
        if (formElement.reportValidity()) {
          registerRedispData(centro).then(function (data) {
            validateRedispCondition(centro, data);
            onRedispSaved(centro, data);
          });
        }
      } else {
        enableEditRedisp(centro);
      }
    }

    function registerRedispData(centro) {
      return dataService.postData('RegisterRedisp', vm.redispModel).then(function (data) {
        setCentroFilled(centro, data);
      });
    }

    function getRedispCondition(done) {
      dataService.postData('RedispCondition').then(function (_ref2) {
        var Results = _ref2.Results;

        vm.RedispCondition = Results;
        done(Results);
      });
    }

    function getCustomerData(cart, done) {
      var CodeCli = cart.Master.CodeDelivLoc || cart.Master.CodeCli;
      dataService.postData('CustomerDetail', { CodeCli: CodeCli }).then(function (_ref3) {
        var Results = _ref3.Results;

        var client = Results;
        var address = client.Master.Address;
        var principal = address.filter(function (x) {
          return x.TpAdrCli === "CLI";
        })[0];
        var transpzone = principal.Transpzone;

        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = cart.Master.Item902[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var item = _step2.value;

            item.rotaDestino = transpzone;
            item.rota = item.rotaOrigem + item.rotaDestino;
            //TO:DO Get Rota for tons
            var _iteratorNormalCompletion3 = true;
            var _didIteratorError3 = false;
            var _iteratorError3 = undefined;

            try {
              for (var _iterator3 = item.Tons[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
                var itemTon = _step3.value;

                itemTon.rota = itemTon.rotaOrigem + item.rotaDestino;
              }
            } catch (err) {
              _didIteratorError3 = true;
              _iteratorError3 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion3 && _iterator3.return) {
                  _iterator3.return();
                }
              } finally {
                if (_didIteratorError3) {
                  throw _iteratorError3;
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        vm.cartRoutes = cart.Master.Item902.map(function (x) {
          return x.rota;
        });

        updateCartData(cart);

        done(cart);
      });
    }

    function verifyRedispCondition(condition, cart, isChangeDeliveryLocation) {
      var required = condition;
      var isRequired = function isRequired(route) {
        return required.indexOf(route) !== -1;
      };

      //vm.page.delivery = "";
      vm.RedispatchRequired = false;
      vm.RedispatchOk = true;

      var _iteratorNormalCompletion4 = true;
      var _didIteratorError4 = false;
      var _iteratorError4 = undefined;

      try {
        for (var _iterator4 = cart.Master.Item902[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
          var item = _step4.value;

          if (isChangeDeliveryLocation) {
            var _iteratorNormalCompletion7 = true;
            var _didIteratorError7 = false;
            var _iteratorError7 = undefined;

            try {
              for (var _iterator7 = item.Tons[Symbol.iterator](), _step7; !(_iteratorNormalCompletion7 = (_step7 = _iterator7.next()).done); _iteratorNormalCompletion7 = true) {
                var itemTon = _step7.value;

                vm.RedispatchRequiredCenter[itemTon.centro] = false;
              }
            } catch (err) {
              _didIteratorError7 = true;
              _iteratorError7 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion7 && _iterator7.return) {
                  _iterator7.return();
                }
              } finally {
                if (_didIteratorError7) {
                  throw _iteratorError7;
                }
              }
            }
          } else {
            vm.RedispatchRequiredCenter[item.Centro] = false;
          }
        }
      } catch (err) {
        _didIteratorError4 = true;
        _iteratorError4 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion4 && _iterator4.return) {
            _iterator4.return();
          }
        } finally {
          if (_didIteratorError4) {
            throw _iteratorError4;
          }
        }
      }

      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = cart.Master.Item902[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var _item = _step5.value;

          var isRotaRequired = false;
          var RedispRequiredCenterControl = false;

          var _iteratorNormalCompletion8 = true;
          var _didIteratorError8 = false;
          var _iteratorError8 = undefined;

          try {
            for (var _iterator8 = _item.Tons[Symbol.iterator](), _step8; !(_iteratorNormalCompletion8 = (_step8 = _iterator8.next()).done); _iteratorNormalCompletion8 = true) {
              var _itemTon = _step8.value;

              isRotaRequired = isRequired(_itemTon.rota);

              var isTypeRedispAllowed = _item.TpStockCode === "L" || _item.TpStockCode === "N";
              var isRedispatchRequired = isRotaRequired && isTypeRedispAllowed;
              if (_item.TpStockCode === "L") isRedispatchRequired = true;

              if (isRedispatchRequired) {
                //if(itemTon.QtdProntEntreg > 0 || itemTon.QtdAtc > 0){
                vm.page.delivery = "X";
                vm.RedispatchRequired = true;
                vm.RedispatchOk = false;
                _item.RedispatchRequired = true;
                if (vm.kart.Master.Editavel) {
                  if (!RedispRequiredCenterControl) {
                    vm.RedispatchRequiredCenter[_itemTon.centro] = true;
                    RedispRequiredCenterControl = true;
                  }
                } else {
                  vm.RedispatchRequiredCenter[_itemTon.centro] = true;
                  RedispRequiredCenterControl = true;
                }

                if (!(vm.cartCenters.filter(function (center) {
                  return center === 'T104';
                }).length > 0)) {
                  vm.cartCenters.push('T104');
                  vm.RedispatchRequiredCenter['T104'] = true;
                }
                //}
                /*else{
                  vm.RedispatchRequiredCenter[itemTon.centro] = false;
                }
                */
              } else {
                _item.RedispatchRequired = false;
              }
            }
          } catch (err) {
            _didIteratorError8 = true;
            _iteratorError8 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion8 && _iterator8.return) {
                _iterator8.return();
              }
            } finally {
              if (_didIteratorError8) {
                throw _iteratorError8;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      updateCartData(cart);

      recoverRedispData(cart);

      ////TO:DO Checar centro obrigaório e selecionar o mesmo
      if (vm.cartCenters && vm.cartCenters.length > 1) {
        var _iteratorNormalCompletion6 = true;
        var _didIteratorError6 = false;
        var _iteratorError6 = undefined;

        try {
          for (var _iterator6 = vm.cartCenters[Symbol.iterator](), _step6; !(_iteratorNormalCompletion6 = (_step6 = _iterator6.next()).done); _iteratorNormalCompletion6 = true) {
            var itemCenter = _step6.value;

            if (vm.RedispatchRequiredCenter[itemCenter]) {
              //activateRedispCentro(itemCenter);
              changeRedispCentro(itemCenter);
              return false;
            }
          }
        } catch (err) {
          _didIteratorError6 = true;
          _iteratorError6 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion6 && _iterator6.return) {
              _iterator6.return();
            }
          } finally {
            if (_didIteratorError6) {
              throw _iteratorError6;
            }
          }
        }
      } else {
        changeRedispCentro(vm.cartCenters[0]);
        //activateRedispCentro(vm.cartCenters[0]);
      }
    }

    function loadCartCenters(cart) {
      var allcenters = [];
      var centers = void 0;

      var _iteratorNormalCompletion9 = true;
      var _didIteratorError9 = false;
      var _iteratorError9 = undefined;

      try {
        for (var _iterator9 = cart.Master.Item902[Symbol.iterator](), _step9; !(_iteratorNormalCompletion9 = (_step9 = _iterator9.next()).done); _iteratorNormalCompletion9 = true) {
          var item = _step9.value;

          //TO:DO set rotaOrigem here

          var _iteratorNormalCompletion11 = true;
          var _didIteratorError11 = false;
          var _iteratorError11 = undefined;

          try {
            for (var _iterator11 = item.Tons[Symbol.iterator](), _step11; !(_iteratorNormalCompletion11 = (_step11 = _iterator11.next()).done); _iteratorNormalCompletion11 = true) {
              var itemTon = _step11.value;

              itemTon.rotaOrigem = origins[itemTon.centro];
            }
          } catch (err) {
            _didIteratorError11 = true;
            _iteratorError11 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion11 && _iterator11.return) {
                _iterator11.return();
              }
            } finally {
              if (_didIteratorError11) {
                throw _iteratorError11;
              }
            }
          }

          item.rotaOrigem = origins[item.Centro];

          var _iteratorNormalCompletion12 = true;
          var _didIteratorError12 = false;
          var _iteratorError12 = undefined;

          try {
            for (var _iterator12 = item.Tons[Symbol.iterator](), _step12; !(_iteratorNormalCompletion12 = (_step12 = _iterator12.next()).done); _iteratorNormalCompletion12 = true) {
              var _itemTon2 = _step12.value;

              //if (itemTon.QtdProntEntreg > 0 || itemTon.QtdAtc > 0){
              allcenters.push(_itemTon2.centro);
              //}
            }
          } catch (err) {
            _didIteratorError12 = true;
            _iteratorError12 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion12 && _iterator12.return) {
                _iterator12.return();
              }
            } finally {
              if (_didIteratorError12) {
                throw _iteratorError12;
              }
            }
          }
        }
      } catch (err) {
        _didIteratorError9 = true;
        _iteratorError9 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion9 && _iterator9.return) {
            _iterator9.return();
          }
        } finally {
          if (_didIteratorError9) {
            throw _iteratorError9;
          }
        }
      }

      if (vm.isRedispatchEdit == true) {
        var oneCenters = cart.Master.Item902.map(function (item) {
          return item.Centro;
        });
        centers = [].concat(_toConsumableArray(new Set(oneCenters))).sort();
      } else {
        centers = [].concat(_toConsumableArray(new Set(allcenters))).sort();
      }

      if (vm.kart.Master.Item902.filter(function (item) {
        return item.RedispatchRequired;
      }).length > 0) {
        //obrigatoriedade de redespacho para centro t104
        if (!(centers.filter(function (center) {
          return center === 'T104';
        }).length > 0)) {
          centers.push('T104');
          vm.RedispatchRequiredCenter['T104'] = true;
        }
      }
      // let allCenters = cart.Master.Item902.map(item => item.Centro);

      // let centers = [...new Set(allcenters)].sort();
      vm.cartCenters = centers;

      var _iteratorNormalCompletion10 = true;
      var _didIteratorError10 = false;
      var _iteratorError10 = undefined;

      try {
        for (var _iterator10 = centers[Symbol.iterator](), _step10; !(_iteratorNormalCompletion10 = (_step10 = _iterator10.next()).done); _iteratorNormalCompletion10 = true) {
          var centro = _step10.value;

          setRedespachoModel(centro, null);
        }
      } catch (err) {
        _didIteratorError10 = true;
        _iteratorError10 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion10 && _iterator10.return) {
            _iterator10.return();
          }
        } finally {
          if (_didIteratorError10) {
            throw _iteratorError10;
          }
        }
      }

      getCustomerData(cart, function (cart) {
        getRedispCondition(function (condition) {
          // console.log("y7y7y7y condition", condition);
          verifyRedispCondition(condition, cart, true);
        });
      });
    }

    function parseOptions(list) {
      var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _.keys(list[0]);

      return list.map(function (item) {
        return {
          name: item[params[1]] || item[params[0]],
          value: item[params[0]]
        };
      });
    }

    function recoverRedispData(cart) {
      if (cart.Master.Editavel) {
        var _iteratorNormalCompletion13 = true;
        var _didIteratorError13 = false;
        var _iteratorError13 = undefined;

        try {
          for (var _iterator13 = cart.Master.Item902[Symbol.iterator](), _step13; !(_iteratorNormalCompletion13 = (_step13 = _iterator13.next()).done); _iteratorNormalCompletion13 = true) {
            var item = _step13.value;

            if (item.RedispatchRequired && item.CnpjRedisp) {
              getRedespachoData(item.CnpjRedisp, item.Centro);
            }
          }
        } catch (err) {
          _didIteratorError13 = true;
          _iteratorError13 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion13 && _iterator13.return) {
              _iterator13.return();
            }
          } finally {
            if (_didIteratorError13) {
              throw _iteratorError13;
            }
          }
        }
      } else {
        try {
          var _iteratorNormalCompletion14 = true;
          var _didIteratorError14 = false;
          var _iteratorError14 = undefined;

          try {
            for (var _iterator14 = cart.Master.Redisp902[Symbol.iterator](), _step14; !(_iteratorNormalCompletion14 = (_step14 = _iterator14.next()).done); _iteratorNormalCompletion14 = true) {
              var _item2 = _step14.value;

              getRedespachoData(_item2.CnpjRedisp, _item2.Centro);
            }
          } catch (err) {
            _didIteratorError14 = true;
            _iteratorError14 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion14 && _iterator14.return) {
                _iterator14.return();
              }
            } finally {
              if (_didIteratorError14) {
                throw _iteratorError14;
              }
            }
          }
        } catch (e) {}
      }
      updateCartData(cart);
    }

    // Validando se existe algum centro selecionado...
    function validationCenters() {
      var isValid = false;
      var redisp = vm.kart.Master.Redisp902;

      // ir nos redisp, caso achar um com um centro colocar true
      var _iteratorNormalCompletion15 = true;
      var _didIteratorError15 = false;
      var _iteratorError15 = undefined;

      try {
        for (var _iterator15 = redisp[Symbol.iterator](), _step15; !(_iteratorNormalCompletion15 = (_step15 = _iterator15.next()).done); _iteratorNormalCompletion15 = true) {
          var centers = _step15.value;

          centers.center ? isValid = true : isValid;
          if (isValid) {
            break;
          }
        }

        // se tiver centro nao necessario colocar centro em todos...
      } catch (err) {
        _didIteratorError15 = true;
        _iteratorError15 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion15 && _iterator15.return) {
            _iterator15.return();
          }
        } finally {
          if (_didIteratorError15) {
            throw _iteratorError15;
          }
        }
      }

      if (isValid) {
        return null;
      }

      // caso tiver, pegar os dados do redisp e repilha em todos os centros...
      var redips = [];
      for (var x = 0; x < vm.cartCenters.length; x++) {
        var data = _extends({}, redisp[0], {
          Centro: vm.cartCenters[x]
        });
        redips.push(data);
      }

      return vm.kart.Master.Redisp902 = redips;
    }

    function cartConfirmation() {
      if (!vm.kart.Master.Editavel) {
        updateRedispValidation();

        if (vm.kart.Master.Redisp902) validationCenters();

        //Verfica os centros que não estão preenchidos
        var validCenters = vm.cartCenters.filter(function (centro) {
          return !vm.redespachoData[centro];
        });

        if (vm.RedispatchOk) {
          vm.kart.Master.Redispatch = vm.page.delivery;

          saveCartData().then(function () {
            $state.go('carts-confirmation');
          });
        } else if (validCenters) {
          localService.openModal('Por favor preencha os dados obrigat\xF3rios do centro ' + validCenters[0]);
        } else {
          localService.openModal('Por favor preencha os dados obrigatórios de redespacho antes de continuar.');
        }
      } else {
        vm.kart.Master.Redispatch = vm.page.delivery;

        saveCartData().then(function () {
          $state.go('carts-confirmation');
        });
      }
    }

    function sendKart() {
      _.extend(vm.kart.Master, setKartAttrs(vm.kart.Master));

      vm.redform.$setSubmitted();

      var cartClone = JSON.parse(JSON.stringify(vm.kart));

      // SAP EXIGE CAMPO STRING, mas o campo da tela não permite
      cartClone.Master.NumAddrRedisp = typeof cartClone.Master.NumAddrRedisp === "undefined" ? "" : cartClone.Master.NumAddrRedisp;
      cartClone.Master.NumAddrRedisp += "";

      if (isEfocusCustomer) {
        cartClone.Master.Item902 = cartClone.Master.Item902.map(function (item) {
          var stock = item.Stock;
          stock.PrecoMaterial = "0.01";
          stock.PrcMat = "0.01";
          return item;
        });
      }

      var shopCart = { Cart: angular.toJson(cartClone) };

      return vm.page.delivery && !vm.redform.$valid ? openModal('Dados de Redespacho Incorretos.') : dataService.postData('SendShoppingCart', shopCart).then(function (_ref4) {
        var Results = _ref4.Results;

        openModal(Results);
        return _.delay(resetClient, 500);
      }, localService.errorHandler);
    }

    function backKart(event) {
      console.log('[backKart]', event, vm.kart);

      if (event && event.relatedTarget && event.relatedTarget.classList.contains('ordem-concluir')) {
        console.log("CANCEL SAVE BACKUP KART !");
        return;
      }

      return saveCartData();
    }

    function saveCartData() {

      return dataService.postData('SaveShoppingCart', {
        Cart: angular.toJson(vm.kart),
        Check: 'false'
      }).then(function (res) {
        localService.setData('kart', vm.kart);
      }, localService.errorHandler);
    }

    function saveRedespachoModel(centro, model) {
      var saved = {};

      saved.CnpjAdr = model.CnpjRedisp; // CNPJ
      saved.BairroCli = model.NeighRedisp; // : "Jardim Presidente Dutra"
      saved.CepCli = model.PostCodRedisp.replace(/[^\d]/g, ''); // : "07172-100"
      saved.CityCli = model.CityRedisp; // : "Guarulhos"
      saved.Complemento = model.ComplRedisp; // : ""
      saved.Email = model.ContactRedisp; // : ""
      saved.FoneAdr = model.PhoneRedisp; // : ""
      saved.InscEstAdrCli = model.InsSttRedisp; // : "796348278111"
      saved.LogrCli = model.AddrRedisp; // : "Marinópolis"
      saved.NumHouseCli = model.NumAddrRedisp; // : "773"
      saved.Razao01Cli = model.NmTransRedisp; // : "TPL LOGISTICA NORTE LTDA"
      saved.Suframa = model.SufrRedisp; // : ""
      saved.UfCliAdr = model.StateRedisp; // : "SP"

      vm.redespachoData[centro] = saved;
    }

    function setRedespachoModel(centro, model, cnpj) {
      var res = model;
      if (res) {
        vm.redespachoData[centro] = res;
        vm.redispModel.CnpjRedisp = res.CnpjAdr; // CNPJ
        vm.redispModel.NeighRedisp = res.BairroCli; // : "Jardim Presidente Dutra"
        vm.redispModel.PostCodRedisp = res.CepCli.replace(/[^\d]/g, ''); // : "07172-100"
        vm.redispModel.CityRedisp = res.CityCli; // : "Guarulhos"
        vm.redispModel.ComplRedisp = res.Complemento; // : ""
        vm.redispModel.ContactRedisp = res.Email; // : ""
        vm.redispModel.PhoneRedisp = res.FoneAdr; // : ""
        vm.redispModel.InsSttRedisp = res.InscEstAdrCli; // : "796348278111"
        vm.redispModel.AddrRedisp = res.LogrCli; // : "Marinópolis"
        vm.redispModel.NumAddrRedisp = res.NumHouseCli; // : "773"
        vm.redispModel.NmTransRedisp = res.Razao01Cli; // : "TPL LOGISTICA NORTE LTDA"
        vm.redispModel.SufrRedisp = res.Suframa; // : ""
        vm.redispModel.StateRedisp = res.UfCliAdr; // : "SP"
        vm.redispModel.Lifnr = res.Lifnr;
      } else {
        vm.redispModel.CnpjRedisp = cnpj || "";
        vm.redispModel.NeighRedisp = "";
        vm.redispModel.PostCodRedisp = "";
        vm.redispModel.CityRedisp = "";
        vm.redispModel.ComplRedisp = "";
        vm.redispModel.ContactRedisp = "";
        vm.redispModel.PhoneRedisp = "";
        vm.redispModel.InsSttRedisp = "";
        vm.redispModel.AddrRedisp = "";
        vm.redispModel.NumAddrRedisp = "";
        vm.redispModel.NmTransRedisp = "";
        vm.redispModel.SufrRedisp = "";
        vm.redispModel.StateRedisp = "";
        vm.redispModel.Lifnr = "";
      }
    }

    function setCentroFilled(centro, data) {
      if (data && !data.error && data.Lifnr) {
        var _iteratorNormalCompletion16 = true;
        var _didIteratorError16 = false;
        var _iteratorError16 = undefined;

        try {
          for (var _iterator16 = vm.kart.Master.Item902[Symbol.iterator](), _step16; !(_iteratorNormalCompletion16 = (_step16 = _iterator16.next()).done); _iteratorNormalCompletion16 = true) {
            var _item3 = _step16.value;

            //if (item.Centro === centro) {
            _item3.Lifnr = data.Lifnr;
            _item3['CnpjRedisp'] = data.CnpjAdr;
            //}
          }
        } catch (err) {
          _didIteratorError16 = true;
          _iteratorError16 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion16 && _iterator16.return) {
              _iterator16.return();
            }
          } finally {
            if (_didIteratorError16) {
              throw _iteratorError16;
            }
          }
        }

        if (vm.kart.Master['Redisp902'] == undefined) {
          vm.kart.Master['Redisp902'] = [];
        }

        var item = vm.kart.Master.Redisp902.find(function (item) {
          return item.Centro == centro;
        });

        if (item) {
          var indexForRemoval = vm.kart.Master.Redisp902.findIndex(function (item) {
            return item.Centro == centro;
          });
          //remove item and add update
          vm.kart.Master.Redisp902.splice(indexForRemoval, 1);
        }

        vm.kart.Master.Redisp902.push({
          CodeCli: vm.kart.Master.CodeCli,
          CodePv: vm.kart.Master.CodePv,
          Centro: centro,
          CodRedespacho: data.Lifnr,
          CnpjRedisp: data.CnpjAdr || data.CnpjRedisp
        });

        vm.RedispatchFilledCenter[centro] = (data.CnpjAdr || data.CnpjRedisp).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");

        updateRedispValidation();
        updateCartData(vm.kart);

        $scope.EditRedispFields[centro] = false;
        $scope.updateRedispDataVisible = true;
      } else {
        console.log("ERROR SETTING REDISP DATA", data);
      }
    }

    function updateRedispValidation() {
      var centros = Object.keys(vm.RedispatchRequiredCenter);

      var valid = centros.reduce(function (isOk, centro) {
        return isOk && (vm.RedispatchFilledCenter[centro] || !vm.RedispatchRequiredCenter[centro]) && (vm.redespachoData[centro] && !vm.redespachoData[centro].invalidRoute || !vm.RedispatchRequiredCenter[centro]);
      }, true);

      if (valid) {
        vm.RedispatchOk = true;
      } else {
        vm.RedispatchOk = false;
      }
    }

    function validateRedispCondition(centro, data) {
      var origin = origins[centro];
      var destination = data.Transpzone;
      var route = origin + destination;
      var invalidRoute = vm.RedispCondition.indexOf(route) !== -1;

      if (invalidRoute) {
        if (vm.redespachoData[centro]) {
          vm.redespachoData[centro].invalidRoute = true;
        }

        vm.RedispatchFilledCenter[centro] = false;

        localService.openModal('Essa rota não é válida, por favor informe outro endereço para redespacho.');

        return false;
      }

      return true;
    }

    function getRedespachoData(cnpj, centro) {
      if (!cnpj) return;

      return dataService.postData('RedespachoData', { cnpj: cnpj }).then(function (data) {
        if (data) {
          setRedespachoModel(centro, data, cnpj);

          setCentroFilled(centro, data);

          $scope.EditRedispFields[centro] = false;
          $scope.updateRedispDataVisible = true;
          $scope.registerRedispDataVisible = false;

          vm.page.delivery = 'X';
          vm.RedispatchRequired = true;

          validateRedispCondition(centro, data);
        } else {
          $scope.EditRedispFields[centro] = true;
          $scope.updateRedispDataVisible = false;
          $scope.registerRedispDataVisible = true;
          setRedespachoModel(centro, null, cnpj);
        }
      }, localService.errorHandler);
    }

    function setKartAttrs(Master) {
      var attrs = ['Razao01Cli', 'LogrCli', 'ComplCli', 'NumHouseCli', 'BairroCli', 'CityCli', 'UfCliAdr'];

      var cartAttrs = {
        VsartDesc: Master.Vsart ? _.findWhere(vm.options.Expedition, { value: Master.Vsart }).name : '',
        DescMagazine: Master.NumMagazine ? _.findWhere(vm.options.Magazine, {
          value: Master.NumMagazine
        }).name : '',
        DataDelivLoc: vm.delivery ? _.chain(vm.delivery).findWhere({ CodeCli: Master.CodeDelivLoc }).pick(attrs).value() : '',
        Redispatch: vm.page.delivery,
        Angdt: parseDate(vm.page.date),
        QaOrder: vm.page.qaorder
      };

      vm.kart.Master.Item902.forEach(function (item) {
        item.QtdMaterial = item.Stock.QtdMaterial;
      });

      return cartAttrs;
    }

    function resetClient() {
      localService.setData('kart', '');

      setPage({
        name: 'BOOKS',
        search: '',
        info: '',
        sort: '',
        filters: '',
        grammage: ''
      });

      return $state.go('home');
    }

    function setPage(page) {
      vm.pages = vm.pages || [];
      _.mergeBy(vm.pages, page, 'name');
      localService.setData('pages', vm.pages);
    }

    function backToCarts() {
      backKart().then(function () {
        $state.go('carts');
      });
    }

    function activateRedispCentro(centro) {

      if (vm.redispCenterSelected) {
        // salva os valores do model inputado
        saveRedespachoModel(vm.redispCenterSelected, vm.redispModel);
      }

      // seta os valores do novo centro, se tiver
      setRedespachoModel(centro, vm.redespachoData[centro]);

      vm.redispCenterSelected = centro;
    }

    function changeRedispCentro(centro) {

      try {
        console.log(vm.RedispatchFilledCenter[centro]);
        var cnpj = vm.RedispatchFilledCenter[centro].replace(/[^\w\s]/gi, '');
        return dataService.postData('RedespachoData', { cnpj: cnpj }).then(function (data) {
          if (data) {
            setRedespachoModel(centro, data, cnpj);
            vm.redispCenterSelected = centro;
          } else {
            setRedespachoModel(centro, null, cnpj);
          }
        }, localService.errorHandler);
      } catch (e) {
        activateRedispCentro(centro);
      }
    }
  }
})();