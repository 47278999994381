'use strict';

(function () {
  'use strict';

  angular.module('app').controller('VersionController', VersionController);

  VersionController.$inject = ['$scope', 'version', 'ngDialog', 'localService'];

  function VersionController($scope, version, ngDialog, localService) {
    var vm = this;
    var vp = $scope.$parent.vm;

    vm.data = version.Master;
    vm.qa = localService.env;
    console.log('[env]', vm.qa);
    vp.inside = false;

    vm.openVersions = function () {
      return ngDialog.open({
        template: 'app/download/versionModal.html',
        plain: false,
        scope: $scope
      });
    };
  }
})();