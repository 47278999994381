'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

var filtersData = void 0;

(function () {
  'use strict';

  var filterBook = {
    transclude: true,
    controller: filterBookController,
    bindings: {
      query: '=',
      range: '=',
      disabled: '=',
      onConfirm: '&',
      onClear: '&'
    },
    templateUrl: 'app/books/components/filterMenu.html'
  };

  /* @ngInject */
  filterBookController.$inject = ['$timeout', 'localService'];

  function filterBookController($timeout, localService) {
    var _this = this;

    var self = this;

    filtersData = localService.getData('filterload');

    this.filters = filtersData;
    this.filterMenuOpen = false;
    this.filterMenuEmpty = checkFilters(this.filters);
    this.activeFiltersCount = 0;
    this.menuActive = '';
    this.sliderControl = false;
    this.slider = {
      min: 0,
      max: 1000,
      options: {
        showTicks: true,
        showTicksValues: true,
        step: 100,
        floor: 0,
        ceil: 1000,
        onChange: function () {
          this.filterMenuEmpty = !this.checkSlider() && checkFilters(this.filters);
          return;
        }.bind(this)
      }
    };

    $timeout(function () {
      if (_this.query) {
        _this.filterMenuEmpty = false;
        parseFilters(_this.query, _this.filters);
      }
      if (_this.range) {
        _this.filterMenuEmpty = false;

        var _range$split = _this.range.split(':'),
            _range$split2 = _slicedToArray(_range$split, 2),
            min = _range$split2[0],
            max = _range$split2[1];

        var slider = _extends({}, _this.slider, { min: min, max: max });

        _this.slider = slider;
      }

      _this.onClear({ cleaner: _this.clearFilters.bind(_this) });

      _this.activeFiltersCount = countActiveFilters(_this.filters);
    });

    this.menuOpen = function (menu) {
      _this.sliderControl = false;

      if (_this.menuActive === menu) {
        _this.menuActive = '';
      } else {
        _this.menuActive = menu;
      }

      if (_this.menuActive === 'grama') {
        return $timeout(function () {
          return _this.sliderControl = true;
        }, 250);
      }

      return false;
    };

    this.toggleMenuFilter = function () {
      _this.filterMenuOpen = !_this.filterMenuOpen;
      _this.menuActive = '';

      return _this.filterMenuOpen ? parseFilters(_this.query, _this.filters) : false;
    };

    this.toggleFilter = function (item) {
      item.active = !item.active;
      _this.activeFiltersCount = countActiveFilters(_this.filters);
      _this.filterMenuEmpty = !_this.checkSlider() && checkFilters(_this.filters, _this);
    };

    this.countItems = function (values) {
      return _.where(values, { active: true }).length;
    };

    this.checkSlider = function () {
      return _this.slider.max - _this.slider.min !== 1000;
    };

    this.applyFilters = function () {
      var filterQuery = stringfyFilters(_this.filters);

      if (filterQuery.length) {
        _this.query = '(' + filterQuery.join(')(') + ')';
        _this.filterMenuOpen = false;
      }

      if (_this.checkSlider()) {
        _this.range = _this.slider.min + ':' + _this.slider.max;
        _this.filterMenuOpen = false;
      }

      return $timeout(_this.onConfirm, 0);
    };

    this.clearMenu = function () {
      _this.clearFilters();
      return $timeout(_this.onConfirm);
    };

    this.clearFilters = function () {
      setFilters(_this.filters);

      _this.filterMenuEmpty = checkFilters(_this.filters);
      _this.filterMenuOpen = false;
      _this.menuActive = '';
      _this.query = '';

      _this.slider.max = 1000;
      _this.slider.min = 0;
      _this.range = '';

      _this.activeFiltersCount = 0;
    };
  }

  angular.module('app').component('filterBook', filterBook);

  function setFilters(filterData, filterList) {
    var id = void 0,
        menu = void 0;

    _.each(filterData, function (_ref) {
      var att = _ref.att,
          values = _ref.values;

      if (filterList) {
        menu = _.findWhere(filterList, { att: att });
      }
      _.each(values, function (filterItem) {
        id = cleanStr(filterItem.id);

        if (menu && menu.values.includes(id)) {
          filterItem.active = true;
        } else {
          filterItem.active = false;
        }
      });
    });
  }

  function cleanStr(string) {
    return ("" + string).replace(/[/\. ,:-]+/g, '').removeAccents();
  }

  function checkFilters(currentFilters, exposed) {
    var isAllUnselected = _.every(currentFilters, function (filterMenu) {
      var filterSelecteds = filterActives(filterMenu);
      return filterSelecteds.length === 0;
    });

    if (isAllUnselected && exposed) {
      exposed.query = '';
    }

    return isAllUnselected;
  }

  function filterActives(_ref2) {
    var values = _ref2.values;

    var actives = _.where(values, {
      active: true
    });
    return actives;
  }

  function stringfyFilters(filterMenus) {
    var filterSelecteds = void 0;
    var filterQuery = [];

    angular.forEach(filterMenus, function (filterMenu) {
      filterSelecteds = filterActives(filterMenu);
      if (filterSelecteds.length) {
        filterQuery.push(stringfyItems(filterMenu, filterSelecteds));
      }
    });

    return filterQuery;
  }

  function stringfyItems(filterMenu, filterSelecteds) {
    var filterParsed = filterSelecteds.map(function (filterItem) {
      return 'Master.FilterNavig.' + cleanStr(filterMenu.att) + ':' + cleanStr(filterItem.id);
    });

    return filterParsed.join('|');
  }

  function parseFilters(filterStr, filterData) {
    var menu = void 0;

    var filterList = filterStr.replace(/[|]/g, ')(').split('(').filter(function (val) {
      return val.indexOf(')') > -1;
    }).map(function (val) {
      return val.replaceAll('Master.FilterNavig.', '').split(')')[0];
    });

    var parsedFilter = filterList.reduce(function (parsedList, filterItem) {
      var _filterItem$split = filterItem.split(':'),
          _filterItem$split2 = _slicedToArray(_filterItem$split, 2),
          att = _filterItem$split2[0],
          value = _filterItem$split2[1];

      menu = _.findWhere(parsedList, { att: att });

      if (menu && menu.att === att) {
        menu.values.push(value);
      } else {
        parsedList.push({ att: att, values: [value] });
      }
      return parsedList;
    }, []);

    return setFilters(filterData, parsedFilter);
  }

  function countActiveFilters(filterData) {
    if (!filterData) return 0;
    var activeCount = filterData.filter(function (rootFilter) {
      var items = filterActives(rootFilter).length;
      rootFilter.activeCount = items;
      return items > 0;
    }).map(function (x) {
      return x.activeCount;
    }).reduce(function (x, y) {
      return x + y;
    }, 0);

    return activeCount;
  }
})();