'use strict';

/* global utils:true */
(function () {
  'use strict';

  var tableCart = {
    controller: tableCartController,
    bindings: {
      model: '=',
      kart: '=',
      warnings: '=',
      page: '<',
      params: '<',
      getTotal: '&'
    },
    templateUrl: 'app/carts/components/tableCart.html'
  };

  tableCartController.$inject = ['$scope', '$state', 'ngDialog', "$timeout", "localService"];

  function tableCartController($scope, $state, ngDialog, $timeout, localService) {
    this.scope = $scope;
    this.state = $state;
    this.ngDialog = ngDialog;
    this.checkRefusal = checkRefusal;
    this.tableOpen = false;
    this.parseCurr = utils.helpers.parseCurr;
    this.getDiscount = getDiscount;
    this.subTotal = subTotal;
    this.regexInputNumber = regexInputNumber;
    this.getType = getType;

    this.removeItem = removeItem;
    this.uniqPrice = uniqPrice;
    this.parseStock = parseStock;
    this.debugParseCurr = debugParseCurr;

    $scope.isEfocusCustomer = localService.getData('isEfocusCustomer');

    var vm = this;

    $timeout(function () {
      vm.model.forEach(function (item) {
        item.PrcBrl = parseFloat(("" + item.PrcBrl).replace(",", "."));
        item.PrcUsd = parseFloat(("" + item.PrcUsd).replace(",", "."));
      });
    }, 0);
  }

  function regexInputNumber(event) {
    var invalidChars = ["-", "+", "e"];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }

  function debugParseCurr(material, val, pres) {
    var parsed = utils.helpers.parseCurr(val, pres);
    return parsed;
  }

  function subTotal(material) {

    var isMaterialZipperToFourDecimalPrice = material.MaterialZipper ? 4 : 2;

    return this.parseCurr(+material.PrcMaterial * +material.Stock.QtdMaterial, isMaterialZipperToFourDecimalPrice) || 0;
  }

  function getDiscount(material, _ref, isSufr) {
    var currency = _ref.currency;

    // TypeError: Cannot read property 'N0T1N555' of undefined
    // at tableCartController.getDiscount (tableCart.js:59)
    var discount = 0;
    var currPrice = 'Preco' + currency.capitalize() + '90';
    // this.scope.$parent.validaMultiplo(material);
    // this.scope.$parent.TotalCarrinhoVisual();


    // MELHORAR
    if (isSufr == "X") {
      if (material.Price[material.Segmento].PrcMat) {
        discount = 100 - material.PrcMaterial * 100 / +material.Price[material.Segmento].PrcMat[currPrice];
      }
    } else {
      if (material.Price[material.Segmento].PrcMat) {
        discount = 100 - material.PrcMaterial * 100 / +material.Price[material.Segmento].PrcMat[currPrice];
      }
    }

    return material.PrcMaterial ? discount.toFixed(4) : 0;
  }

  function uniqPrice(material) {
    var groupCode = material.MaterialCode.substr(0, 9);
    var curr = this.page.currency.capitalize();
    _.chain(this.model).filter(function (groupMaterial) {
      return groupMaterial.MaterialCode.includes(groupCode);
    }).each(function (materialPrice) {
      return materialPrice['Prc' + curr] = material['Prc' + curr];
    });

    return this.getTotal();
  }

  function getType(stockCode) {
    return { N: 'NORM', PR: 'PREV', L: 'L. DEF', P: 'PILOT', C: 'CART' }[stockCode];
  }

  function parseStock(stock) {
    return stock ? Math.floor(stock) : '0';
  }

  function removeItem(material) {
    if (this.kart.Master.St24Ho || this.kart.Master.Editavel && material.ItemOv != undefined) {
      return this.checkRefusal(material);
    }

    // MELHORAR

    this.model = _.reject(this.model, function (rejectedMaterial) {
      var deleteCondition = rejectedMaterial.MaterialCode === material.MaterialCode && rejectedMaterial.TpStockCode === material.TpStockCode && rejectedMaterial.Nuance === material.Nuance && rejectedMaterial.Centro === material.Centro && rejectedMaterial.Tonalidade === material.Tonalidade;

      if (deleteCondition) {
        console.log("DELETE FROM CART", rejectedMaterial);
      }

      return deleteCondition;
    });

    return this.getTotal();
  }

  function checkRefusal(material, saveKart) {

    console.log(this, material);

    var modal = this.ngDialog.open({
      template: 'app/carts/cartsMot.html',
      controller: 'CartsMotController as vm',
      plain: false,
      resolve: {
        material: function setMaterial() {
          return material;
        }
      },
      scope: this.scope
    });

    return modal;
  }

  angular.module('app').component('tableCart', tableCart);
})();