'use strict';

(function () {
    'use strict';

    angular.module('app').directive('autoActive', autoActive);

    function autoActive($location) {
        return {
            restrict: 'A',
            scope: false,
            link: function link(scope, element) {
                var setActive = function setActive() {
                    var arr = $location.path().split('-');
                    var path = arr[1] === 'fav' || arr[1] && arr[1].includes('add') ? $location.path() : arr[0];

                    if (path && path !== '/') {
                        angular.forEach(element.find('a'), function (link) {
                            if (link.href.match(path + '(?=\\?|$)') && !angular.element(link).hasClass('disabled')) {
                                angular.element(link).addClass('active');
                            } else {
                                angular.element(link).removeClass('active');
                            }
                        });
                    }
                };

                _.delay(setActive);
                scope.$on('$locationChangeSuccess', setActive);
            }
        };
    }
})();