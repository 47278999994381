'use strict';

(function () {
  'use strict';

  var stockLine = function stockLine() {
    return {
      //replace: true,
      templateUrl: 'app/books/directives/stockLine.html',
      link: link
    };

    function link() {
      // console.log('[stockLine]', scope.material);
    }
  };

  angular.module('app').directive('stockLine', stockLine);
})();