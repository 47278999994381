'use strict';

(function () {
  'use strict';

  angular.module('app').directive('fileinput', fileInput);

  function fileInput() {
    return {
      scope: {
        fileinput: '=',
        filepreview: '='
      },
      link: function link(scope, element) {
        element.bind('change', function (changeEvent) {
          var reader = new FileReader();

          scope.fileinput = changeEvent.target.files[0];
          reader.onload = function (loadEvent) {
            scope.$apply(function () {
              scope.filepreview = loadEvent.target.result;
            });
          };
          reader.readAsDataURL(scope.fileinput);
        });
      }
    };
  }
})();