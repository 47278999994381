'use strict';

(function () {
  'use strict';

  angular.module('app').directive('inputCnpjAddress', inputCnpjAddress);

  function inputCnpjAddress(dataService, localService) {
    return {
      scope: {
        model: '=ngModel',
        name: '@'
      },
      require: 'ngModel',
      replace: true,
      link: link,
      templateUrl: 'app/clients/directives/inputCnpjAddress.html'
    };

    function link(scope, element, attrs, ctrl) {
      var input = element.find('input');

      input.on('blur', function () {
        var value = ctrl.$modelValue.CnpjAdr;

        if (value) {
          element.attr('disabled', '');
          verifyCnpj(value);
          return scope.$apply();
        }
        return false;
      });

      input.on('keydown keypress', function (event) {
        return event.which === 13 ? event.target.blur() : false;
      });

      function verifyCnpj(cnpj) {
        return cnpj ? dataService.postData('VerifyAddress', { CnpjCli: cnpj }).then(function (_ref) {
          var Results = _ref.Results;

          var obj = {
            Isento: true,
            Exist: false
          };
          var kind = attrs.name.substr(-3).toLowerCase();
          var address = scope.model;

          element.removeAttr('disabled');

          if (Results.length) {
            obj = Results[0].Master;
            obj.Isento = obj.InscEstAdrCli === 'ISENTO';
            obj.Exist = true;
            obj.CepCli = obj.CepCli.replace(/[^0-9]/g, '');
            obj.FoneAdr = obj.FoneAdr.replace(/[^0-9]/g, '');
            obj.TpAdrCli = kind.toUpperCase();

            _.extend(address, obj);
          } else {
            obj.TpAdrCli = kind.toUpperCase();
            _.extend(address, obj);
            ctrl.$setValidity('usedCpnj', true);
          }
        }, checkError) : element.removeAttr('disabled');
      }

      function checkError(res) {
        element.removeAttr('disabled');
        localService.errorHandler(res);
        return ctrl.$setValidity('usedCpnj', false);
      }
    }
  }
})();