'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

(function () {
  'use strict';

  angular.module('app').directive('quantityInput', quantityInput);

  function quantityInput(localService, rulesService, dataService) {
    return {
      replace: true,
      link: link,
      scope: {
        material: '=',
        currency: '=',
        limits: '=',
        kart: '=',
        finance: '='
      },
      require: 'ngModel',
      template: '<input type="text" class="form-control ctrl-md" only-digits placeholder="0">'
    };

    function link(scope, element, attrs, ctrl) {
      var currency = scope.currency;
      var currentTom = scope.material.TomIndex || scope.material.selectedTom;
      var centroSelecionado = scope.material.CentroSelecionado;

      // console.log("75fh8 QUANTITY INPUT >>", scope.material);

      var segmento = scope.material.Stock[currentTom][centroSelecionado].SegmentoEstoque;

      // console.log("QUANTITY INPUT checkPrice", scope.material.Price[segmento].Price);

      var price = checkPrice(scope.material.Price[segmento].Price, scope.material.TpStockCode, currency);
      var viewModel = void 0;

      if (price && !scope.kart || !price) {
        element.attr('disabled', '');
      }

      _.delay(function (newCtrl) {
        viewModel = newCtrl.$viewValue;
      }, 100, ctrl);

      function customValidator(ngModelValue) {
        updateFilterBook();

        var kart = scope.kart || {};
        var material = scope.material;
        var finance = scope.finance;
        //const currency = scope.currency;

        var test = { Error: false };
        var cleanModel = parseInt(ngModelValue, 10);

        // let cleanModel = ngModelValue ? ngModelValue.toString().replace(/[^\d.-]/g, '') : 0;
        var kartItems = kart.Master.Open ? removeItem(kart.Master.Item902, material) : [];

        if (!cleanModel && !viewModel && !ngModelValue) {
          // console.log("4f5tgt66 ::", "NÃO TEM VALOR !cleanModel && !viewModel && !ngModelValue", cleanModel, viewModel, ngModelValue);
          return true;
        }

        if (!cleanModel && viewModel) {
          // console.log("4f5tgt66 ::", "NÃO TEM VALOR MAS TEM ALGO !cleanModel && viewModel", cleanModel, viewModel);

          // if (cleanModel === 0) {
          // console.log("4f5tgt66 ::", "novo valor é ZERO, remover do carrinho", cleanModel);
          // }


          kart.Master.Item902 = kartItems;
          viewModel = '';
          saveKart(kart);
          return true;
        }

        test = rulesService.checkStockLimits(scope.limits, ngModelValue, material.TpStockCode, material.UnidMed);

        var calculatedStockATC = function () {
          var resultados = [];
          var _iteratorNormalCompletion = true;
          var _didIteratorError = false;
          var _iteratorError = undefined;

          try {
            for (var _iterator = material.Stock[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
              var obj = _step.value;

              var valores = Object.entries(obj);
              var _iteratorNormalCompletion2 = true;
              var _didIteratorError2 = false;
              var _iteratorError2 = undefined;

              try {
                for (var _iterator2 = valores[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
                  var _ref = _step2.value;

                  var _ref2 = _slicedToArray(_ref, 2);

                  var centro = _ref2[0];
                  var valor = _ref2[1];

                  if (valor.hasOwnProperty("QtdEstApos")) {
                    resultados.push({
                      centro: centro,
                      stockATC: Number(valor.QtdEstApos),
                      tonalidade: valor.Tonalidade || valor.tonalidade
                    });
                  }
                }
              } catch (err) {
                _didIteratorError2 = true;
                _iteratorError2 = err;
              } finally {
                try {
                  if (!_iteratorNormalCompletion2 && _iterator2.return) {
                    _iterator2.return();
                  }
                } finally {
                  if (_didIteratorError2) {
                    throw _iteratorError2;
                  }
                }
              }
            }
          } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion && _iterator.return) {
                _iterator.return();
              }
            } finally {
              if (_didIteratorError) {
                throw _iteratorError;
              }
            }
          }

          return resultados;
        }();

        // Se o estoque for P e ATC
        var isValidStockATCtoTypeP = material.DateAtcDelv ? true : false;
        var listOfStocksATC = material.Disp['P0T0N555'] ? material.Disp['P0T0N555'].ListDisp : [];

        if (material.TpStockCode === 'P' && isValidStockATCtoTypeP) {
          if (!material.DateAtcDelv) {
            localService.openModal('Atenção!!! Selecionar a data de chegada do ATC.');
            ctrl.$setViewValue(viewModel);
            ctrl.$render();
            return false;
          }

          var _material$DateAtcDelv = material.DateAtcDelv.split(','),
              _material$DateAtcDelv2 = _slicedToArray(_material$DateAtcDelv, 2),
              dateATC = _material$DateAtcDelv2[0],
              stockATC = _material$DateAtcDelv2[1];
          // Lista de estoques disponiveis para ATC

          // Verificar match entre o centro e a data


          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = listOfStocksATC[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var stock = _step3.value;

              if (stock.Centro === stockATC && stock.Date === dateATC) {
                // verificar se o saldo é maior que o solicitado
                if (stock.Qtde === 0) {
                  localService.openModal('Não há estoque ATC disponível');
                  ctrl.$setViewValue(viewModel);
                  ctrl.$render();
                  return false;
                }

                if (ngModelValue > stock.Qtde) {
                  localService.openModal('A quantidade máxima deve ser menor ou igual à ' + stock.Qtde);
                  ctrl.$setViewValue(viewModel);
                  ctrl.$render();
                  return false;
                }
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        } else if (material.TpStockCode === 'P' && !isValidStockATCtoTypeP) {
          var listOfStocks = material.Stock[material.TomIndex];
          // Se o estoque por default for zerado...
          if (listOfStocks[material.CentroSelecionado].QtdProntEntreg === 0) {
            // selecionar o próximo estoque com algo disponivel
            for (var _stock in listOfStocks) {
              if (listOfStocks[_stock].QtdProntEntreg > ngModelValue) {
                material.CentroSelecionado = _stock;
                material.Stock[0][_stock]['QtdMaterial'] = ngModelValue;
              }
            }
          }
          var ZeroStocks = 0;
          var dispStock = 0;

          for (var _stock2 in listOfStocks) {
            if (listOfStocks[_stock2].QtdProntEntreg === 0) ZeroStocks++;
            if (listOfStocks[_stock2].QtdProntEntreg > dispStock) dispStock = listOfStocks[_stock2].QtdProntEntreg;
          }

          if (ZeroStocks === Object.values(listOfStocks).length || ngModelValue > dispStock) {

            var isEmptyOrNotStockATC = 'ATENÇÃO!!! O item selecionado não tem disponibilidade';

            var _iteratorNormalCompletion4 = true;
            var _didIteratorError4 = false;
            var _iteratorError4 = undefined;

            try {
              for (var _iterator4 = listOfStocksATC[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
                var _stock3 = _step4.value;

                if (_stock3.Qtde > 0) isEmptyOrNotStockATC = 'Atenção!!! Selecionar a data de chegada do ATC.';
              }
            } catch (err) {
              _didIteratorError4 = true;
              _iteratorError4 = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion4 && _iterator4.return) {
                  _iterator4.return();
                }
              } finally {
                if (_didIteratorError4) {
                  throw _iteratorError4;
                }
              }
            }

            localService.openModal(isEmptyOrNotStockATC);
            ctrl.$setViewValue(viewModel);
            ctrl.$render();
            return false;
          }
        }

        material.QtdMaterial = ngModelValue;

        // Nem todos os clientes tem esse dado, podendo retornar undefined ou null, por isso o importante tipar caso não exista
        var isProfileFutureSale = kart.Master.isFutureSales ? true : false;

        var stockAtc = calculatedStockATC;
        test = rulesService.checkMaterialStockLimits(scope.limits, ngModelValue, material, null, kart.Master.Editavel, isProfileFutureSale, stockAtc, material.DateAtcDelv);

        test = test.Error ? test : rulesService.checkStockType(ngModelValue, kartItems, material.TpStockCode);

        // test = test.Error ? test : rulesService.checkStockDate(material.DateMat.OutVend);

        if (test.Error) {
          localService.openModal(test.Error);
          ctrl.$setViewValue(viewModel);
          ctrl.$render();
          return false;
        }

        if (viewModel !== ngModelValue) {

          if (cleanModel) {
            kart.Master.Open = true;
            kart.Master.CodeCurr = currency.toUpperCase();
            var TomMaterialItem;

            if (material.Tonalidade) {
              TomMaterialItem = material.Tonalidade;
            } else {
              try {
                TomMaterialItem = material.tonalidadeKeys[material.selectedTom]['name'];
              } catch (e) {
                TomMaterialItem = 'T0';
              }
            }

            if (kartItems.filter(function (e) {
              return e.MaterialCode === material.MaterialCode && e.Stock.Tonalidade === TomMaterialItem;
            }).length > 0) {

              for (var i = 0; i < kartItems.length; i++) {
                if (kartItems[i].MaterialCode == material.MaterialCode && kartItems[i].Stock.Tonalidade == TomMaterialItem) {
                  kartItems[i].Stock.QtdMaterial = ngModelValue;
                  //kartItems[i] = updateItem;
                  break;
                }
              }

              var updateItem = setKartItems(material, kart.Master.AntCli, kart.Master.isSufr, finance, currency, isProfileFutureSale);
            } else {
              kartItems.push(setKartItems(material, kart.Master.AntCli, kart.Master.isSufr, finance, currency, isProfileFutureSale));
            }
          }

          viewModel = ngModelValue;
          console.log('kartItems', kartItems);

          kart.Master.Item902 = kartItems;
          saveKart(kart);
        }

        return true;
      }

      element.on('blur', function () {
        var validation = customValidator(ctrl.$viewValue);

        ctrl.$setValidity(attrs.customValidate, validation);
        scope.$apply();
      });

      element.on('keydown keypress', function (_ref3) {
        var target = _ref3.target,
            which = _ref3.which;
        return which === 13 ? target.blur() : false;
      });
    }

    function removeItem() {
      var items = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var material = arguments[1];

      // MELHORAR
      var itemsLeft = _.reject(items, function (_ref4) {
        var MaterialCode = _ref4.MaterialCode,
            TpStockCode = _ref4.TpStockCode,
            Nuance = _ref4.Nuance,
            Centro = _ref4.Centro,
            Tonalidade = _ref4.Tonalidade;

        var tom = material.TomIndex || material.selectedTom;
        var rejectedCentro = material.CentroSelecionado;
        var rejected = MaterialCode === material.MaterialCode;
        rejected = rejected && TpStockCode === material.TpStockCode;
        rejected = rejected && Nuance === material.Stock[tom][rejectedCentro].Nuance;
        rejected = rejected && Centro === rejectedCentro;
        rejected = rejected && Tonalidade === material.Stock[tom][rejectedCentro].Tonalidade;

        return rejected;
      });

      return itemsLeft;
    }

    function saveKart(kart) {

      kart.Master.Item902.forEach(function (item) {
        item.QtdMaterial = item.Stock.QtdMaterial;
      });

      return dataService.postData('SaveShoppingCart', {
        Cart: angular.toJson(kart),
        Check: 'false'
      }).then(function (_ref5) {
        var Cart = _ref5.Cart;
        return localService.setData('kart', Cart);
      }, localService.errorHandler);
    }

    function setKartItems(material, AntCli, isSufr, finance, currency, isProfileFutureSale) {
      var props = [
      // 'IpadX', // NOT FOUND, don't need it
      'DescMat', 'MaterialCode', 'MaterialCodOld', 'NomeMercado', 'GroupMaterial', 'UnidMed', 'NcmCode', 'PercIpi', 'QtdeMultiplo', 'CorteMin', 'DateAtcDelv', // NOT FOUND
      'TpStockCode', 'Ipad', 'IsPreview', 'PrecoPromo', 'Price', 'Stock', 'Tons'];

      var Master = _.pick(material, props);
      var Centro = material.CentroSelecionado;
      var OldCentro = material.CentroSelecionado;

      if (material.DateAtcDelv) {
        var stockModel = material.DateAtcDelv.split(',');
        material.DateAtcDelv = stockModel[0];
        Centro = stockModel[1];
        material.CentroSelecionado = Centro;
      }

      var TomIndex = material.TomIndex || material.selectedTom;
      var Stock = Master.Stock[TomIndex][Centro];

      var Segmento = Stock.SegmentoEstoque; // material.TpStockCode + "0" + stock.Tonalidade + stock.Nuance;


      //TO:DO check pilotagem is < 10
      if (Segmento.substring(0, 1) == "N" && material.Tipo != 'CAR') {
        if (Master.Stock[TomIndex][Centro].QtdMaterial <= 10) {
          Stock.SegmentoEstoque = "P" + Segmento.substringn(1);
          Segmento = Stock.SegmentoEstoque;
        }
      }

      var Price = Master.Price[Segmento].PrcMat;

      if (typeof Master.Price[Segmento].PrcMat.PrecoBrl == "string") {
        Master.Price[Segmento].PrcMat.PrecoBrl = Master.Price[Segmento].PrcMat.PrecoBrl.replace('.', '').replace(',', '.');
        Master.Price[Segmento].PrcMat.PrecoUsd = Master.Price[Segmento].PrcMat.PrecoUsd.replace('.', '').replace(',', '.');
      }

      if (isSufr == "X") {
        if (Segmento != "N0NAZF") {
          Master.Price[Segmento].Price.BRL = (parseFloat(parseWithComma(Master.Price[Segmento].Price.BRL.replace('.', '')), 10) * finance.FatorFinanceiroSUFRAMA).toFixed(2);
          Master.Price[Segmento].Price.USD = (parseFloat(parseWithComma(Master.Price[Segmento].Price.USD.replace('.', '')), 10) * finance.FatorFinanceiroSUFRAMA).toFixed(2);
        }
        var FatorFinanceiroSet = "FatorFinanceiro" + currency.toUpperCase();
        var SufrBrl90 = (parseFloat(Master.Price[Segmento].Price.BRL, 10) * Math.pow(finance[FatorFinanceiroSet], 90 / 30)).toFixed(2);
        var SufrUsd90 = (parseFloat(Master.Price[Segmento].Price.USD, 10) * Math.pow(finance[FatorFinanceiroSet], 90 / 30)).toFixed(2);
        Price.PrecoBrl90 = SufrBrl90;
        Price.PrecoUsd90 = SufrUsd90;
      } else {
        var SufrBrl90 = Master.Price[Segmento].Price90.BRL;
        var SufrUsd90 = Master.Price[Segmento].Price90.USD;
      }

      var dispATCmounth = 0;
      if (material.DateAtcDelv) {
        for (var i = 0; i < material['Disp'][Segmento]['ListDisp'].length; i++) {
          if (material.DateAtcDelv.slice(3) == material['Disp'][Segmento]['ListDisp'][i].Date.slice(3)) {
            dispATCmounth = dispATCmounth + material['Disp'][Segmento]['ListDisp'][i].Qtde;
          }
        }

        //TO:DO get QtdMaterial do new Center
        var OldStock = Master.Stock[TomIndex][OldCentro];
        Stock['QtdMaterial'] = OldStock.QtdMaterial;
      }

      material.Tons = [];
      //Check Ton for Tons itens
      var _iteratorNormalCompletion5 = true;
      var _didIteratorError5 = false;
      var _iteratorError5 = undefined;

      try {
        for (var _iterator5 = material.Centros[Symbol.iterator](), _step5; !(_iteratorNormalCompletion5 = (_step5 = _iterator5.next()).done); _iteratorNormalCompletion5 = true) {
          var item = _step5.value;

          material.Tons.push({
            centro: item,
            QtdProntEntreg: material.Stock[TomIndex][item].QtdProntEntreg,
            QtdAtc: dispATCmounth
          });
        }
      } catch (err) {
        _didIteratorError5 = true;
        _iteratorError5 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion5 && _iterator5.return) {
            _iterator5.return();
          }
        } finally {
          if (_didIteratorError5) {
            throw _iteratorError5;
          }
        }
      }

      var isMaterialZipperToFourDecimalPrice = material.MaterialZipper ? 4 : 2;

      var matInKart = _extends({}, Master, {
        Centro: Centro,
        PrcMaterial: Master.PrcMaterial ? Master.PrcMaterial : currency === 'BRL' ? parseFloat(Price.PrecoBrl90.replace(',', '.')).toFixed(isMaterialZipperToFourDecimalPrice) : parseFloat(Price.PrecoUsd90.replace(',', '.')).toFixed(isMaterialZipperToFourDecimalPrice),
        Deposito: Stock.Deposito,
        Nuance: Stock.Nuance,
        MaterialZipper: material.MaterialZipper,
        Tonalidade: Stock.Tonalidade,
        TomIndex: TomIndex,
        Segmento: Stock.SegmentoEstoque,
        QtdMaterial: Stock.QtdMaterial || material.QtdMaterial,
        Stock: Stock,
        Abgru: '',
        DatePeProg: '00000000',
        PrcBrl: AntCli ? Price.PrecoBrl : Price.PrecoBrl90,
        PrcUsd: AntCli ? Price.PrecoUsd : Price.PrecoUsd90,
        TpStockCode: material.Tipo != 'CAR' ? Stock.SegmentoEstoque.substring(0, 1) : material.TpStockCode,
        suframa: {
          OldPrcBrl: Master.Price[Segmento].PrcMat.PrecoBrl,
          OldPrcUsd: Master.Price[Segmento].PrcMat.PrecoUsd,
          OldPrc90Brl: Master.Price[Segmento].Price90.BRL,
          OldPrc90Usd: Master.Price[Segmento].Price90.USD,
          PrcBrl: Master.Price[Segmento].Price.BRL,
          PrcUsd: Master.Price[Segmento].Price.USD,
          Prc90Brl: SufrBrl90,
          Prc90Usd: SufrUsd90
        },
        StockMATC: dispATCmounth,
        Tons: material.Tons
      });

      if (isProfileFutureSale === false) {
        if (material.TpStockCode !== 'C') {
          if (material.QtdPETotal === 0 && !matInKart['DateAtcDelv'] || material.QtdPETotal < Stock.QtdMaterial && !matInKart['DateAtcDelv']) {
            matInKart['DateAtcDelv'] = material.Disp[Stock.SegmentoEstoque].ListDisp[0].Date;
          }
        }
      }

      // Se o estoque for do tipo pilotagem, e o centro default estiver zerado, recolocar para o proximo disponivel


      console.log("ENTRADA DE ITEM NO CARRINHO", matInKart);

      return matInKart;
    }

    function checkPrice(stockPrice, stockCode) {
      var currency = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';

      var rawPrice = stockPrice[currency];
      rawPrice = ("" + stockPrice[currency]).replace(",", ".");
      var consideredPrice = parseFloat(rawPrice, 10);
      var condition = consideredPrice && stockCode !== 'C' || stockCode === 'C';
      return condition;
    }

    function updateFilterBook() {
      var filterBookRef = document.querySelector("filter-book");
      if (filterBookRef) {
        var vm = angular && angular.element && angular.element(filterBookRef) && angular.element(filterBookRef).scope && angular.element(filterBookRef).scope() && angular.element(filterBookRef).scope().vm;

        if (vm && vm.validateConditionalCenter) {
          setTimeout(function () {
            vm.validateConditionalCenter();
          }, 0);
        }
      }
    }

    function parseWithComma(value) {
      return parseFloat(("" + value).replace(",", "."), 10); // fix values with ","
    }

    function removeDuplicates(myArr, prop) {
      return myArr.filter(function (obj, pos, arr) {
        return arr.map(function (mapObj) {
          return mapObj[prop];
        }).indexOf(obj[prop]) === pos;
      });
    }
  }
})();