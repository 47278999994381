'use strict';

var utils = utils || {};

utils.helpers = {
  setScrollTop: function setScrollTop() {
    var value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

    var el = document.querySelector('.table tbody');

    if (el) {
      el.scrollTop = value;
    }
    return el;
  },
  getScrollTop: function getScrollTop() {
    var el = document.querySelector('.table tbody');

    return el.scrollTop;
  },
  deepGet: function deepGet(obj, properties) {
    // If we have reached an undefined/null property
    // then stop executing and return undefined.
    if (obj === undefined || obj === null) {
      return false;
    }

    // If the path array has no more elements, we've reached
    // the intended property and return its value.
    if (properties.length === 0) {
      return obj;
    }

    // Prepare our found property and path array for recursion
    var foundSoFar = obj[properties[0]];
    var remainingProperties = properties.slice(1);

    return utils.helpers.deepGet(foundSoFar, remainingProperties);
  },
  parseCurr: function parseCurr(value) {
    var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;

    return value ? Number(value).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: digits
    }).split('$')[1].replace(/\s+/g, '') : value;
  },
  dispDate: function dispDate() {
    var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

    date.setDate(date.getDate() + days);
    return [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2)].join('/');
  },
  checkDate: function checkDate(date) {
    return +date || date && date.includes('/') ? date.replaceAll('-', '/') : '-';
  },
  parseDate: function parseDate(date) {
    return date ? [('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()].join('/') : '';
  },
  parseDateProgramada: function parseDateProgramada(date) {
    return date && Date.prototype.isPrototypeOf(date) ? [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()].join('/') : '';
  },
  parseFullDate: function parseFullDate(date) {
    return date && Date.prototype.isPrototypeOf(date) ? [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()].reverse().join('') : '';
  },

  parseStringDate: function parseStringDate(str) {
    var date = void 0,
        parse = void 0;

    if (str && (parse = str.match(/^(\d{4})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/))) {
      date = new Date(+parse[1], +parse[2] - 1, +parse[3]);
      if (date.getMonth() === Number(parse[2]) - 1) {
        return [('0' + date.getDate()).slice(-2), ('0' + (date.getMonth() + 1)).slice(-2), date.getFullYear()].join('/');
      }
    }
    return +str === 0 ? '' : str;
  }
};