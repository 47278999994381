'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

/* SERVIÇO PARA CHAMADA DAS FUNÇÕES CORDOVA OFFLINE */

(function () {
  'use strict';

  angular.module('app').service('cordovaService', cordovaService);
  cordovaService.$inject = ['$q', 'localService', 'loadingStatusInterceptor'];

  /* @ngInject */
  function cordovaService($q, localService, loadingStatusInterceptor) {
    var token = void 0,
        headers = void 0;

    return {
      postFunc: postFunc,
      startFunc: startFunc,
      validateFunc: validateFunc,
      loginFunc: loginFunc,
      imageFunc: imageFunc
    };

    function postFunc(action, data) {
      var deff = $q.defer();
      var arr = _.values(data) || [];

      loadingStatusInterceptor.request(true);
      cordova.exec(function (res) {
        loadingStatusInterceptor.response(true);
        return res.Error ? deff.reject(res) : deff.resolve(res);
      }, function (err) {
        loadingStatusInterceptor.responseError(err);
        return deff.reject({ Error: 'Erro Cordova: ' + err });
      }, 'Core', 'cordova' + action, arr);
      return deff.promise;
    }

    function loginFunc(data) {
      var deff = $q.defer();
      var arr = _.values(data) || [];

      loadingStatusInterceptor.request(true);
      cordova.exec(function (res) {
        loadingStatusInterceptor.response(true);
        if (res.data.Error) {
          deff.reject(res.data);
        } else {
          localService.setData('user', res.data.User);
          setHeader(res.data.User);
          deff.resolve(res.data);
        }
      }, function (err) {
        loadingStatusInterceptor.responseError(err);
        return deff.reject({ Error: 'Erro Cordova: ' + err });
      }, 'Core', 'cordovaLogin', arr);
      return deff.promise;
    }

    function startFunc() {
      var deff = $q.defer();
      var user = localService.getData('user') || {};

      token = user.Token;
      cordova.exec(function (data) {
        return deff.notify(data);
      }, function (err) {
        return deff.reject({ Error: 'Erro Cordova: ' + err });
      }, 'Core', 'cordovaImportData', [token]);
      return deff.promise;
    }

    function validateFunc(data) {
      var deff = $q.defer();
      var arr = _.values(data) || [];

      cordova.exec(function (res) {
        var response = res.data;

        headers = response ? { headers: { 'x-access-token': response.Token } } : {};
        deff.resolve(response);
      }, function (err) {
        return deff.reject({ Error: 'Erro Cordova.' + err });
      }, 'Core', 'cordovaValidateLogin', [].concat(_toConsumableArray(arr), [token]));
      return deff.promise;
    }

    function imageFunc(id, type) {
      var deff = $q.defer();
      var path = type === 'DETAILS' ? 'large_' : 'small_';

      cordova.exec(function (data) {
        return deff.resolve(data);
      }, function (err) {
        return deff.reject({ Error: 'Erro Cordova: ' + err });
      }, 'Core', 'cordovaGetImage', [path + id + '.jpg']);
      return deff.promise;
    }

    function setHeader(user) {
      token = user.Token;
      headers = user ? { headers: { 'x-access-token': token } } : {};
      return headers;
    }
  }
})();