'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

(function () {
  'use strict';

  var listBookController = function () {
    function listBookController() {
      _classCallCheck(this, listBookController);

      this.tableOpen = false;
    }

    _createClass(listBookController, [{
      key: 'setBook',
      value: function setBook(book, hyer) {
        return this.bookClick({ book: book, hyer: hyer });
      }
    }, {
      key: 'submitForm',
      value: function submitForm() {
        return this.sortClick();
      }
    }]);

    return listBookController;
  }();

  var listBook = {
    controller: listBookController,
    bindings: {
      model: '=',
      page: '<',
      sortClick: '&',
      bookClick: '&'
    },
    templateUrl: 'app/books/components/listBook.html'
  };

  angular.module('app').component('listBook', listBook);
})();