'use strict';

(function () {
  'use strict';

  angular.module('app').directive('contactsList', contactsList);

  function contactsList() {
    return {
      replace: true,
      templateUrl: 'app/clients/components/contactsList.html',
      controller: contactsListController,
      controllerAs: '$ctrl',
      bindToController: {
        list: '=',
        remove: '&',
        disabled: '@'
      }
    };

    function contactsListController() {
      var _this = this;

      this.removeItem = function (item) {
        return _this.remove({ item: item, list: 'contacs' });
      };
    }
  }
})();