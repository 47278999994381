'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global utils:true */
(function () {
  'use strict';

  var tableStatusController = function () {
    function tableStatusController() {
      _classCallCheck(this, tableStatusController);

      this.tableOpen = false;
      this.parseCurr = utils.helpers.parseCurr;
    }

    _createClass(tableStatusController, [{
      key: 'subTotal',
      value: function subTotal(material) {
        return this.parseCurr(material.Stock.QtdMaterial * material.PrcMaterial);
      }
    }]);

    return tableStatusController;
  }();

  var tableStatus = {
    controller: tableStatusController,
    bindings: {
      items: '='
    },
    templateUrl: 'app/orders/components/tableStatus.html'
  };

  angular.module('app').component('tableStatus', tableStatus);
})();