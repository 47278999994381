'use strict';

/* global utils:true */
(function () {
  'use strict';

  angular.module('app').factory('CartModel', function () {
    'use strict';

    return {
      Abgru: function Abgru(record) {
        return record.Abgru;
      },
      MaterialCode: function MaterialCode(record) {
        return record.MaterialCode;
      },
      MaterialCodOld: function MaterialCodOld(record) {
        return record.MaterialCodOld;
      },
      DescMat: function DescMat(record) {
        return record.DescMat;
      },
      NcmCode: function NcmCode(record) {
        return record.NcmCode;
      },
      PercIpi: function PercIpi(record) {
        return record.PercIpi;
      },
      DatePeProg: function DatePeProg(record) {
        return record.DatePeProg;
      },
      QtdMaterial: function QtdMaterial(record) {
        return record.QtdMaterial;
      },
      PrcMaterial: function PrcMaterial(record) {
        return record.PrcMaterial;
      },
      GroupMaterial: function GroupMaterial(record) {
        return record.GroupMaterial;
      },
      Ipad: function Ipad(record) {
        return record.Ipad;
      },
      IpadX: function IpadX(record) {
        return record.IpadX;
      },
      UnidMed: function UnidMed(record) {
        return record.UnidMed;
      },
      QtdeMultiplo: function QtdeMultiplo(record) {
        return record.QtdeMultiplo;
      },
      CorteMin: function CorteMin(record) {
        return record.CorteMin;
      },
      StockDetail: function StockDetail(record) {
        return record.StockDetail;
      },
      TpStockCode: function TpStockCode(record, params) {
        return params.stockCode;
      },
      ParsedMult: function ParsedMult(record) {
        return utils.helpers.parseCurr(record.QtdeMultiplo) + ' ' + record.UnidMed;
      },
      Price: function Price(record) {
        return record.Price;
      },
      PrcMat: function PrcMat(record) {
        var existsMaterialTypeZipper = record.Master.MaterialZipper || null;
        var isMaterialZipperToFourDecimalPrice = existsMaterialTypeZipper ? 4 : 2;

        return {
          BRL: utils.helpers.parseCurr(record.Price.PrecoBrl90, isMaterialZipperToFourDecimalPrice),
          USD: utils.helpers.parseCurr(record.Price.PrecoUsd90, isMaterialZipperToFourDecimalPrice)
        };
      },
      StkMat: function StkMat(record, params) {
        var stock = record.Stock[params.stockCode] || {};
        return ['QtdEstApos', 'QtdEstendida', 'QtdProntEntreg'].reduce(function (result, key) {
          result[key] = Math.floor(+stock[key]) || '-';
          return result;
        }, {});
      }
    };
  });
})();