'use strict';

(function () {
  'use strict';

  angular.module('app').directive('scrollEnds', scrollEnds);

  function scrollEnds($window) {
    return {
      restrict: 'A',
      link: function link(scope, element, attrs) {
        var raw = element[0];
        var offset = parseInt(attrs.offset, 10);
        var debounced = _.debounce(function () {
          console.log('[SCROLL]');
          return raw.scrollTop + raw.offsetHeight >= raw.scrollHeight ? scope.$apply(attrs.scrollEnds) : false;
        }, 300);

        element.css('height', $window.innerHeight - offset + 'px');
        element[0].addEventListener('scroll', debounced);

        raw.scrollTop = 0;
      }
    };
  }
})();