'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global utils:true */
(function () {
  'use strict';

  var tableBookController = function () {
    function tableBookController($rootScope) {
      _classCallCheck(this, tableBookController);

      this.tableOpen = false;
      this.dispDate = utils.helpers.dispDate;
      this.fakeMaterialTracker = 0;
      var scope = this;

      $rootScope.$on('materialsUpdate', function () {
        scope.processRenderModel();
      });
    }

    _createClass(tableBookController, [{
      key: '$onInit',
      value: function $onInit() {
        console.log('onInit', this.model);

        var scope = this;
        scope.renderModel = [];
        this.getTomProp = getTomProp;

        this.updateListDisp = updateListDisp;
        this.toggle = false;

        var page = scope.page;

        function deepClone(obj) {
          return JSON.parse(JSON.stringify(obj));
        }

        function tonalizeMaterial(material, tom) {
          // inclua o tom no material
          material = deepClone(material);
          // material.Stock = tom;

          var centros = Object.keys(tom).sort();
          material.Centros = centros;

          // if ( ! material.CentroSelecionado) {
          // u76 O BRANCHCODE CHEGA ATÉ AQUI?
          // u76 temos acesso ao escopo pai? pode procurar lá
          // console.log("u76 BRANCH tonalizeMaterial", page.branch);
          if (centros.indexOf(page.branch) > -1) {
            material.CentroSelecionado = page.branch;
          } else {
            material.CentroSelecionado = centros[0];
          }
          // } else {
          // console.log(">> 5g45h65 tonalizeMaterial EXISTE CentroSelecionado", material.CentroSelecionado);
          // }

          var currentTom = tom[material.CentroSelecionado].TomIndex;
          material.selectedTom = currentTom;

          return material;
        }

        function tomRow(material, tom) {
          scope.fakeMaterialTracker++;

          // console.log(">> 5g45h65 tomRow TOM", tom);

          material = deepClone(material);
          // retire tudo o que está renderizando na tela e injete os campos de tonalidade
          // FAKE MATERIAL DELETED PROPS
          material.tonalidadeRow = true;

          material.MaterialCodeTrack = material.MaterialCodeTrack + "" + scope.fakeMaterialTracker;

          material.TomNuance = tom.TomNuance;

          // material.Stock = tom;

          var centros = Object.keys(tom).sort();
          material.Centros = centros;

          // if ( ! material.CentroSelecionado) {
          // console.log("u76 BRANCH tomRow", page.branch);
          if (centros.indexOf(page.branch) > -1) {
            material.CentroSelecionado = page.branch;
          } else {
            material.CentroSelecionado = centros[0];
          }
          // } else {
          // console.log(">> 5g45h65 tomRow EXISTE CentroSelecionado", material.CentroSelecionado);
          // }

          var currentTom = tom[material.CentroSelecionado].Tonalidade;
          material.Tonalidade = currentTom;
          var countQtdPETotal = 0;
          //add T1 qtd to tons
          material.Tons.forEach(function (item, index) {
            try {
              item.QtdProntEntreg = tom[item.centro].QtdProntEntreg;
              countQtdPETotal = countQtdPETotal + tom[item.centro].QtdProntEntreg;
            } catch (e) {}
          });

          material.QtdPETotal = countQtdPETotal;

          return material;
        }

        function setAndRemove(material, tom, properties) {
          properties.forEach(function (property) {
            material[property] = tom[property];
            delete tom[property];
          });

          return { material: material, tom: tom };
        }

        function getTomProp(material, prop, tom) {
          var stock = material.Stock;
          var centro = material.CentroSelecionado;

          var filter = stock.filter(function (stockItem) {
            if (typeof stockItem[centro] == "undefined" || typeof stock[tom][centro] == "undefined") {
              return false;
            }
            var tomItem = stockItem[centro].Tonalidade;
            var tomStock = stock[tom][centro].Tonalidade;
            var comp = tomItem == tomStock;
            return comp;
          });

          if (!filter.length) {
            return "";
          }

          var tomProp = filter[0][centro][prop];
          return tomProp;
        }

        function adaptMaterialToView(material) {
          console.log("MATERIAL::", material);
          if (material.tonalidadeRow) {
            material.viewDescMat = "";
            material.viewDescCor = "";
            material.viewMaterialCode = "";
            material.viewMaterialCodOld = "";
            material.viewNomeMercado = "";
            material.viewParsedMult = "";
            material.viewParsedMin = "";
          } else {
            material.viewDescMat = material.DescMat;
            material.viewDescCor = material.DescCor;
            material.viewMaterialCode = material.MaterialCode;
            material.viewMaterialCodOld = material.MaterialCodOld;
            material.viewNomeMercado = material.NomeMercado || "---";
            material.viewParsedMult = material.ParsedMult;
            material.viewParsedMin = material.ParsedMin;
          }
        }

        function addToRender(material) {
          filterListDisp(material);

          adaptMaterialToView(material);

          var newTonArr = [];
          var totalQtdPETons = 0;

          material.Centros.forEach(function (item, index) {
            try {
              totalQtdPETons = totalQtdPETons + material.Stock[material.TomIndex][item].QtdProntEntreg;
              newTonArr.push({
                centro: item,
                QtdProntEntreg: material.Stock[material.TomIndex][item].QtdProntEntreg
              });
            } catch (e) {}
          });
          material.QtdPETotal = totalQtdPETons;
          material.Tons = newTonArr;
          scope.renderModel.push(material);
        }

        function updateListDisp(material) {

          filterListDisp(material);
        }

        function filterListDisp(material) {

          material.TomIndex = material.TomIndex || 0;

          var TomIndex = material.TomIndex;
          var CentroSelecionado = material.CentroSelecionado;
          var Stock = material.Stock[TomIndex][CentroSelecionado];
          var SegmentoEstoque = Stock.SegmentoEstoque;
          var hasListDisp = material.Disp[SegmentoEstoque] && material.Disp[SegmentoEstoque].ListDisp;
          // let hasListDisp = !!material.Disp[material.Stock[material.TomIndex][material.CentroSelecionado].SegmentoEstoque];

          //Calcula o total de estoque ATC nos centros
          var QtdEstAposTotal = 0;
          for (var i = 0; i < material.Centros.length; i++) {
            QtdEstAposTotal = QtdEstAposTotal + material.Stock[TomIndex][material.Centros[i]].QtdEstApos;
          }

          //material
          //material.Stock[TomIndex][CentroSelecionado].QtdEstApos = QtdEstAposTotal;

          if (hasListDisp) {
            /*
            material.Disp[SegmentoEstoque].ListDispView = hasListDisp.filter(x => {
              return x.Centro === CentroSelecionado
            });
            */
            material.Disp[SegmentoEstoque].ListDispView = hasListDisp;
            material['isATCStock'] = true;
          } else {
            material['isATCStock'] = false;
          }
        }

        // MODEL READY FOR RENDERING
        function processRenderModel() {
          var alreadyProcessed = {};
          var rawModelLength = scope.model.length;
          var renderModelLength = scope.renderModel.length;

          for (var i = 0; i < rawModelLength; i++) {
            var mat = scope.model[i].MaterialCode;
            for (var j = 0; j < renderModelLength; j++) {
              if (mat == scope.renderModel[j].MaterialCode) {
                alreadyProcessed[mat] = true;
                break;
              }
            }
          }

          var toProcess = scope.model.filter(function (x) {
            return !alreadyProcessed[x.MaterialCode];
          });

          toProcess.forEach(function (material) {
            // console.log("234234 TO PROCESS", material, material.MaterialCode, material.Stock);
            var adaptedStock = material.Stock;
            if (adaptedStock.length) {
              var tons = deepClone(adaptedStock);

              var primeiroTom = tons.shift();
              Object.keys(primeiroTom).forEach(function (centro) {
                primeiroTom[centro].index = 0;
              });

              //TO:DO check here ton row
              material.TomNuance = primeiroTom.TomNuance;

              var tonalizedMaterial = tonalizeMaterial(material, primeiroTom);
              tonalizedMaterial.TomIndex = 0;
              addToRender(tonalizedMaterial);

              if (tons.length > 0) {
                tons.forEach(function (tom, index) {
                  // console.log("u76 process ", tons, tom, index);
                  var currentIndex = index + 1;
                  Object.keys(tom).forEach(function (centro) {
                    tom[centro].index = currentIndex;
                  });

                  var tomBranch = Object.keys(tom)[0];
                  //if (page.branch === "" || page.branch == tomBranch) {
                  var fakeRow = tomRow(material, tom);
                  fakeRow.TomIndex = currentIndex;
                  addToRender(fakeRow);
                  //}
                });
              }
            } else {
              addToRender(material);
            }
          });
        }

        scope.processRenderModel = processRenderModel;

        processRenderModel();
      }

      // $onChanges(changesObj) {
      //   console.log('onChanges', changesObj);
      // }

      // $postLink() {
      //   console.log('postLink', this.model);
      // }

    }, {
      key: 'sortStock',
      value: function sortStock(stock, stockCode) {
        return 'Stock.' + stockCode + '.' + stock;
      }
    }, {
      key: 'submitForm',
      value: function submitForm() {
        return this.sortClick();
      }
    }, {
      key: 'updateLine',
      value: function updateLine(materialCode) {
        return materialCode ? this.lineClick({ list: [materialCode] }) : false;
      }
    }]);

    return tableBookController;
  }();

  //   function tableBookController() {
  //   // constructor() {
  //   this.tableOpen = false;
  //   this.dispDate = utils.helpers.dispDate;

  //   console.log('onInit', this.$onInit, this.model);

  //   this.$onInit = () => {
  //     console.log('onInit', this, this.model);
  //   };

  //   this.$onChanges = changesObj => {
  //     console.log('onChanges', changesObj);
  //   };

  //   this.sortStock = (stock, stockCode) => {
  //     return 'Stock.' + stockCode + '.' + stock;
  //   };

  //   this.submitForm = () => {
  //     return this.sortClick();
  //   };

  //   this.updateLine = materialCode => {
  //     return materialCode ? this.lineClick({ list: [materialCode] }) : false;
  //   };
  // }

  tableBookController.$inject = ["$rootScope"];

  var tableBook = {
    controller: tableBookController,
    bindings: {
      model: '=',
      kart: '=',
      page: '<',
      params: '<',
      sortClick: '&',
      lineClick: '&',
      loadMore: '&'
    },
    templateUrl: 'app/books/components/tableBook.html'
  };

  angular.module('app').component('tableBook', tableBook);
})();