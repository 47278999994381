'use strict';

(function () {
    'use strict';

    angular.module('app').directive('stockButton', stockButton);

    function stockButton(dataService, localService) {
        return {
            replace: true,
            scope: {
                model: '=',
                kart: '=',
                code: '<',
                items: '<'
            },
            templateUrl: 'app/directives/stockButton.html',
            link: function link(scope) {
                scope.parseStock = function (value) {
                    return Math.floor(value);
                };

                scope.saveChange = function (code, model) {
                    if (scope.kart && scope.code) {
                        var items = scope.kart.Master.Item902;

                        model = model.split(',');

                        angular.forEach(items, function (item) {
                            if (item.MaterialCode === code) {
                                item.DateAtcDelv = model[0];
                            }
                        });

                        if (items && items.length) {
                            saveKart(scope.kart);
                        }
                    }
                };

                function saveKart(kart) {
                    return dataService.postData('SaveShoppingCart', {
                        Cart: angular.toJson(kart),
                        Check: 'false'
                    }).then(function (_ref) {
                        var Cart = _ref.Cart;
                        return localService.setData('kart', Cart);
                    }, localService.errorHandler);
                }
            }
        };
    }
})();