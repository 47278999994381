'use strict';

(function () {
    'use strict';

    angular.module('app').directive('simpleAccordion', simpleAccordion);

    function simpleAccordion($timeout) {
        return {
            link: link,
            restrict: 'A'
        };

        function link(scope, element) {
            $timeout(function () {
                element.addClass('accordion-content');
                element.find('h3').bind('click', function (_ref) {
                    var target = _ref.target;

                    var box = angular.element(target).parent();
                    var sib = siblings(box[0]);

                    return box.hasClass('disabled') ? box.removeClass('active') : (angular.element(sib).removeClass('active'), box.toggleClass('active'));
                });
            });

            scope.clickTest = function (event) {
                return console.log('[clickAccTest]', event);
            };
        }

        function prevSiblings(target) {
            var sibling = [];
            var n = target;

            while (n = n.previousElementSibling) {
                sibling.push(n);
            }
            return sibling;
        }

        function nextSiblings(target) {
            var sibling = [];
            var n = target;

            while (n = n.nextElementSibling) {
                sibling.push(n);
            }
            return sibling;
        }

        function siblings(target) {
            var prev = prevSiblings(target) || [];
            var next = nextSiblings(target) || [];

            return prev.concat(next);
        }
    }

    angular.module('app').directive('accordionTitle', accordionTitle);

    function accordionTitle() {
        return {
            replace: true,
            transclude: true,
            templateUrl: 'app/directives/accordionTitle.html'
        };
    }
})();