"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* global utils:true */
(function () {
  'use strict';

  var tableOrdersController = function () {
    function tableOrdersController($scope, localService) {
      _classCallCheck(this, tableOrdersController);

      this.tableOpen = false;
      this.parseCurr = utils.helpers.parseCurr;
      this.localService = localService;

      var self = this;
      var vm = $scope.$parent.$parent.vm;

      this.onDataAvailable(self, "items", function () {
        console.log("9898u ITEMS", self.items);
        // self.items = vm.raItens;
      });
    }

    _createClass(tableOrdersController, [{
      key: "onDataAvailable",
      value: function onDataAvailable(root, key, callback) {
        var timeout = 5000;
        var interval = 250;
        var startTime = +new Date();

        function verify() {
          if (root[key]) {
            callback();
          } else {
            if (+new Date() - startTime < timeout) {
              setTimeout(verify, interval);
            }
          }
        }

        verify();
      }
    }, {
      key: "selectAll",
      value: function selectAll(self) {
        setTimeout(function () {
          var vm = self.$parent.$ctrl;
          var checked = vm.checkSelectAll;
          vm.items.forEach(function (item) {
            item.raSelected = checked;
          });
          self.$parent.$apply();
        }, 0);
      }
    }, {
      key: "qtdChanged",
      value: function qtdChanged(item) {
        var max = +item.QtdMaterialAen;
        var itemQtd = item.raQtd.replace(",", ".").replace(/[^.0-9]/g, "");
        var isValid = +itemQtd <= max;

        if (isValid) {
          item.raQtd = itemQtd;
          item.raSelected = true;
        } else {
          item.raQtd = max;
          item.raSelected = true;
          this.localService.openModal('O valor máximo para este item é ' + max);
        }
      }
    }]);

    return tableOrdersController;
  }();

  /* @ngInject */


  tableOrdersController.$inject = ['$scope', 'localService'];

  var tableOrders = {
    controller: tableOrdersController,
    bindings: {
      items: '=',
      ra: '<',
      isLegado: '='

    },
    templateUrl: 'app/orders/components/tableOrders.html'
  };

  angular.module('app').component('tableOrders', tableOrders);
})();