'use strict';

/* global utils:true */
(function () {
  'use strict';

  angular.module('app').directive('currencyButton', currencyButton);

  function currencyButton(rulesService) {
    return {
      replace: true,
      scope: {
        price: '=',
        params: '=',
        currency: '=',
        promo: '=',
        material: '=',
        kart: '='
      },
      link: link,
      templateUrl: 'app/directives/currencyButton.html'
    };

    function link(scope, elem, attrs) {

      if (scope.params) {
        scope.items = pagtList(scope.price, scope.params.FinancyFactor, scope.promo, scope);

        //console.log("f4g45t SCOPE", scope.items);
      }
    }

    function pagtList(price, finance, promo, scope) {
      var arrValues = ['CASH', 'A VISTA', '30 DIAS', '45 DIAS', '60 DIAS', '90 DIAS', 'PROMO', 'SALES', 'T106 - A VISTA', 'T106 - 90 DIAS'];

      scope.activePrice = "";
      return arrValues.map(function (item) {
        //var dateNow = new Date();
        //var date = new Date(promo.DtPromoValR);
        if (item == "PROMO") {
          if (promo) {
            if (parseFloat(promo.PrPromoR) > 0) {
              var itemPreco = {
                name: item
              };
              if (parseFloat(promo.PrPromoR) > 0) {
                itemPreco['BRL'] = promo.PrPromoR.replace('.', ",");
              } //TO:DO
              if (parseFloat(promo.PrPromoD) > 0) {
                itemPreco['USD'] = promo.PrPromoD.replace('.', ",");
              }
            } else if (parseFloat(promo.PrPromoD) > 0) {
              var itemPreco = {
                name: item
              };
              if (parseFloat(promo.PrPromoR) > 0) {
                itemPreco['BRL'] = promo.PrPromoR.replace('.', ",");
              } //TO:DO
              if (parseFloat(promo.PrPromoD) > 0) {
                itemPreco['USD'] = promo.PrPromoD.replace('.', ",");
              }
            } else {
              var itemPreco = {
                name: item,
                BRL: "0",
                USD: "0"
              };
            }
          }
        } else if (item == "SALES") {
          if (promo) {
            if (parseFloat(promo.PrSaleR) > 0) {
              var itemPreco = {
                name: item
              };
              if (parseFloat(promo.PrSaleR) > 0) {
                itemPreco['BRL'] = promo.PrSaleR.replace('.', ",");
              }
              if (parseFloat(promo.PrSaleD) > 0) {
                itemPreco['USD'] = promo.PrSaleD.replace('.', ",");
              }
            } else if (parseFloat(promo.PrSaleD) > 0) {
              var itemPreco = {
                name: item
              };
              if (parseFloat(promo.PrSaleR) > 0) {
                itemPreco['BRL'] = promo.PrSaleR.replace('.', ",");
              }
              if (parseFloat(promo.PrSaleD) > 0) {
                itemPreco['USD'] = promo.PrSaleD.replace('.', ",");
              }
            } else {
              var itemPreco = {
                name: item,
                BRL: "0",
                USD: "0"
              };
            }
          }
        } else if (item == "T106 - A VISTA") {
          //FATOR T106 = 9.5 (1.095%)
          try {
            if (scope.material.centro.includes('T106')) {
              var BRLprice = Number(parseFinance(price.BRL, 'A VISTA', finance, 'BRL', scope.material.MaterialZipper).replace(',', '.'));
              var USDprice = Number(parseFinance(price.USD, 'A VISTA', finance, 'USD', scope.material.MaterialZipper).replace(',', '.'));
              var itemPreco = {
                name: item,
                BRL: (9.5 / 100 * BRLprice + BRLprice).toFixed(2).toString().replace('.', ','),
                USD: (9.5 / 100 * USDprice + USDprice).toFixed(2).toString().replace('.', ',')
              };
            } else {
              var itemPreco = {
                name: item,
                BRL: "0",
                USD: "0"
              };
            }
          } catch (e) {
            var itemPreco = {
              name: item,
              BRL: "0",
              USD: "0"
            };
          }
        } else if (item == "T106 - 90 DIAS") {
          try {
            if (scope.material.centro.includes('T106')) {
              var BRLprice = Number(parseFinance(price.BRL, '90 DIAS', finance, 'BRL', scope.material.MaterialZipper).replace(',', '.'));
              var USDprice = Number(parseFinance(price.USD, '90 DIAS', finance, 'USD', scope.material.MaterialZipper).replace(',', '.'));
              var itemPreco = {
                name: item,
                BRL: (9.5 / 100 * BRLprice + BRLprice).toFixed(scope.material.MaterialZipper).toString().replace('.', ','),
                USD: (9.5 / 100 * USDprice + USDprice).toFixed(scope.material.MaterialZipper).toString().replace('.', ',')
              };
            } else {
              var itemPreco = {
                name: item,
                BRL: "0",
                USD: "0"
              };
            }
          } catch (e) {
            var itemPreco = {
              name: item,
              BRL: "0",
              USD: "0"
            };
          }
        } else {
          var itemPreco = {
            name: item,
            BRL: parseFinance(price.BRL, item, finance, 'BRL', scope.material.MaterialZipper),
            USD: parseFinance(price.USD, item, finance, 'USD', scope.material.MaterialZipper)
          };
        }
        try {
          if (scope.currency == "BRL" && itemPreco.name == "SALES" || itemPreco.name == "PROMO") {

            if (parseFloat(itemPreco.BRL.replace(/\./g, "").replace(',', ".")) > 0 || parseFloat(itemPreco.BRL.replace(/\./g, "").replace(',', ".")) > 0) {
              scope.activePrice = "activePrice";
            }
          }
          if (scope.currency == "USD" && itemPreco.name == "SALES" || itemPreco.name == "PROMO") {
            if (parseFloat(itemPreco.USD.replace(/\./g, "").replace(',', ".")) > 0 || parseFloat(itemPreco.USD.replace(/\./g, "").replace(',', ".")) > 0) {
              scope.activePrice = "activePrice";
            }
          }
        } catch (e) {}

        return itemPreco;
      });
    }

    function parseFinance() {
      var price = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
      var item = arguments[1];
      var finance = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var curr = arguments[3];
      var materialZipper = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;

      var prz = item.replace(/\D/g, '');

      price = ("" + price).replace(",", ".");
      price = price.replace(/\.(?![^.]+$)|[^0-9.]/, "");

      var parsedPrice = parseFloat(price, 10);

      var isMaterialZipperToFourDecimalPrice = materialZipper ? 4 : 2;

      var calculated = rulesService.calcFinancy(parsedPrice, prz, finance.FatorRedutor, finance['FatorFinanceiro' + curr], false).toFixed(isMaterialZipperToFourDecimalPrice);

      return calculated;
    }
  }
})();