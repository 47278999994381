'use strict';

(function () {
    'use strict';

    angular.module('app').directive('stockpeButton', stockpeButton);

    function stockpeButton(dataService, localService) {
        return {
            replace: true,
            scope: {
                model: '=',
                kart: '=',
                code: '<',
                items: '<'
            },
            templateUrl: 'app/directives/stockpeButton.html',
            link: function link(scope) {

                scope.updateListDisp = function (material) {
                    scope.model = true;
                    filterListDisp(material);
                };

                function filterListDisp(material) {

                    material.TomIndex = material.TomIndex || 0;

                    var TomIndex = material.TomIndex;
                    var CentroSelecionado = material.CentroSelecionado;
                    var Stock = material.Stock[TomIndex][CentroSelecionado];
                    var SegmentoEstoque = Stock.SegmentoEstoque;
                    var hasListDisp = material.Disp[SegmentoEstoque] && material.Disp[SegmentoEstoque].ListDisp;
                }
            }
        };
    }
})();