'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

// COMPONENTE CONTROLE DE TABELAS
// USADO NAS TELAS ORDERS E STATUS

(function () {
  'use strict';

  angular.module('app').controller('TableController', TableController);

  function TableController(localService) {
    var vm = this;

    vm.records = [];
    vm.pages = [];
    vm.data = {};
    vm.page = {
      info: {},
      search: '',
      sort: ' ',
      filters: '',
      scroll: 0
    };

    vm.setInfo = setInfo;
    vm.setPage = setPage;
    vm.startPage = startPage;
    vm.checkScroll = checkScroll;
    vm.setRecords = setRecords;

    function setInfo(info, response) {
      var CurrentPage = response.CurrentPage,
          Records = response.Records,
          TotalPages = response.TotalPages,
          TotalRecords = response.TotalRecords;


      return _extends({ CurrentPage: CurrentPage, Records: Records, TotalPages: TotalPages, TotalRecords: TotalRecords }, info);
    }

    function startPage(attrs) {
      vm.page = _extends({}, vm.page, attrs);
      vm.pages = localService.getData('pages') || [];
      vm.page = vm.pages.find(function (page) {
        return page.name === attrs.name;
      }) || vm.page;
      return vm.page;
    }

    function setPage(page) {
      vm.pages = vm.pages || [];
      vm.page = _extends({}, vm.page, page);

      _.mergeBy(vm.pages, page, 'name');
      return localService.setData('pages', vm.pages);
    }

    function setRecords(records, key) {
      vm.records = records ? removeDuplicates([].concat(_toConsumableArray(vm.records), _toConsumableArray(records)), key) : [];
      return vm.records;
    }

    function removeDuplicates(myArr, prop) {
      return myArr.filter(function (obj, pos, arr) {
        return arr.map(function (mapObj) {
          return mapObj[prop];
        }).indexOf(obj[prop]) === pos;
      });
    }

    function checkScroll() {
      if (+vm.page.info.CurrentPage < +vm.page.info.TotalPages && +vm.page.info.TotalRecords > vm.records.length) {
        vm.data.Page = +vm.page.info.CurrentPage + 1;
        vm.page.info.CurrentPage = vm.data.Page;
        return true;
      }
      return false;
    }
  }
})();