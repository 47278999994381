'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

/* global utils:true */
// SERVIÇO - REGRAS DE NEGÓCIO PARA MATERIAIS E CARRINHO DE COMPRAS
(function () {
  'use strict';

  angular.module('app').service('rulesService', rulesService);

  function rulesService() {
    var vm = this;
    vm.parseCurr = utils.helpers.parseCurr;

    return {
      calcFinancy: calcFinancy,
      checkMultiple: checkMultiple,
      checkStockLimits: checkStockLimits, // DEPRECATED
      checkMaterialStockLimits: checkMaterialStockLimits,
      checkStockDate: checkStockDate,
      checkStockType: checkStockType,
      overPrice: overPrice,
      checkStockCentro: checkStockCentro
    };

    function calcFinancy(Price, PrzMed, FatorRedutor, FatorFinanceiro, isSuframa) {
      if (isSuframa) {
        var calc = Price * FatorRedutor;
      } else {
        var calc = PrzMed <= 14 ? Price - Price * FatorRedutor : Price * Math.pow(FatorFinanceiro, PrzMed / 30);
      }

      return calc;
    }

    function checkMaterialStockLimits(limit, qtdMaterial, material, deadline, Editavel, isFutureSales, stocksAtc, DateAtcDelv) {
      qtdMaterial = parseInt(qtdMaterial, 10);
      var stockCode = material.TpStockCode;
      var unid = material.UnidMed;
      if (material.Posex) material.Posex = Number(material.Posex);

      var tom;
      if (typeof material.TomIndex === "number") {
        tom = material.TomIndex;
      } else if (material.Stock.Tonalidade) {
        tom = material.Stock.Tonalidade;
      } else if (material.selectedTom) {
        tom = material.selectedTom;
      } else {
        console.log(" ! ! ! TOM NÃO ENCONTRADO", material);
      }

      var centro;
      if (material.CentroSelecionado) {
        centro = material.CentroSelecionado;
      } else if (material.Centro) {
        centro = material.Centro;
      } else {
        console.log(" ! ! ! CENTRO NÃO ENCONTRADO", material);
      }

      var dispPE;

      if (material.Stock && material.Stock[tom] && material.Stock[tom][centro] && material.Stock[tom][centro].QtdProntEntreg) {
        dispPE = parseInt(material.Stock[tom][centro].QtdProntEntreg, 10);
      } else if (material.Stock && typeof material.Stock.QtdProntEntreg !== 'undefined') {
        dispPE = parseInt(material.Stock.QtdProntEntreg, 10);
      } else {
        console.log("DISP PE NÃO ENCONTRADO!", material);
        dispPE = 0;
      }

      // Apenas usuarios com perfil para venda futura podem estar habilitados a colocar materiais mesmo com estoque zerado
      if (!limit && !isFutureSales) {
        return {
          Qtd: 0,
          Error: 'Parametro não pode ser nulo'
        };
      }

      var cloneMaterial = material;
      cloneMaterial.Tonalidade ? null : cloneMaterial.Tonalidade = 'T0';

      if (!isFutureSales && !DateAtcDelv && qtdMaterial > cloneMaterial.QtdPETotal && cloneMaterial.TpStockCode === 'N') {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {

          for (var _iterator = stocksAtc[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var stock = _step.value;


            // Material sem segmentação
            if (stock.Tonalidade === 'NA') {
              if (stock.stockATC >= qtdMaterial) {
                return {
                  Qtd: 0,
                  Error: 'Atenção!!! Selecionar a data de chegada do ATC.'
                };
              }
            } else {
              if (stock.tonalidade.toUpperCase() === cloneMaterial.Tonalidade.toUpperCase()) {
                if (stock.stockATC >= qtdMaterial) {
                  return {
                    Qtd: 0,
                    Error: 'Atenção!!! Selecionar a data de chegada do ATC.'
                  };
                }
              }
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return {
          Qtd: 0,
          Error: 'ATENÇÃO!!! O item selecionado não tem disponibilidade'
        };
      }

      if (DateAtcDelv && !isFutureSales && material.TpStockCode === 'N') {
        var centerAndDataATC = DateAtcDelv.split(',');

        // Materiais sem segmentação
        if (cloneMaterial.tonalidadeKeys[0].name === 'NA') {

          console.log('cloneMaterial.Stock[0]', cloneMaterial.Stock[0]);

          var stocks = Object.values(cloneMaterial.Stock).map(function (obj) {
            return _extends({
              Center: Object.keys(obj)[0] }, Object.values(obj)[0]);
          });

          var stockByCenterSelected = stocks.find(function (stock) {
            return stock.Center === cloneMaterial.CentroSelecionado;
          });

          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = cloneMaterial.Disp[stockByCenterSelected.SegmentoEstoque].ListDisp[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var stockCenter = _step2.value;

              if (stockCenter.Centro === centerAndDataATC[1] && stockCenter.Date === centerAndDataATC[0]) {
                if (qtdMaterial > stockCenter.Qtde) {

                  return {
                    Qtd: 0,
                    Error: 'ATENÇÃO!!! O item selecionado não tem disponibilidade'
                  };
                }
              }
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }
        } else {
          // segmentação normal

          var _iteratorNormalCompletion3 = true;
          var _didIteratorError3 = false;
          var _iteratorError3 = undefined;

          try {
            for (var _iterator3 = cloneMaterial.Disp['N0T0N555'].ListDisp[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
              var _stockCenter = _step3.value;

              if (_stockCenter.Centro === centerAndDataATC[1] && _stockCenter.Date === centerAndDataATC[0]) {
                if (qtdMaterial > _stockCenter.Qtde) {

                  return {
                    Qtd: 0,
                    Error: 'ATENÇÃO!!! O item selecionado não tem disponibilidade'
                  };
                }
              }
            }
          } catch (err) {
            _didIteratorError3 = true;
            _iteratorError3 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion3 && _iterator3.return) {
                _iterator3.return();
              }
            } finally {
              if (_didIteratorError3) {
                throw _iteratorError3;
              }
            }
          }
        }
      }

      // TODO: Tonalidade foi retirado, colocar antes de commitar
      if (isFutureSales && cloneMaterial.TpStockCode === 'N' && !DateAtcDelv && qtdMaterial > cloneMaterial.QtdPETotal) {

        if (stocksAtc.length > 0) {
          var _iteratorNormalCompletion4 = true;
          var _didIteratorError4 = false;
          var _iteratorError4 = undefined;

          try {
            for (var _iterator4 = stocksAtc[Symbol.iterator](), _step4; !(_iteratorNormalCompletion4 = (_step4 = _iterator4.next()).done); _iteratorNormalCompletion4 = true) {
              var _stock = _step4.value;

              if (_stock.stockATC > 0 && _stock.tonalidade.toUpperCase() === cloneMaterial.Tonalidade.toUpperCase()) {
                return {
                  Qtd: 0,
                  Error: 'Atenção!!! Selecionar a data de chegada do ATC.'
                };
              }
            }
          } catch (err) {
            _didIteratorError4 = true;
            _iteratorError4 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion4 && _iterator4.return) {
                _iterator4.return();
              }
            } finally {
              if (_didIteratorError4) {
                throw _iteratorError4;
              }
            }
          }
        }
      }

      if (_.has(limit.TpStockCode, stockCode)) {
        var qtdLimit = +limit.TpStockCode[stockCode][unid] || 0;
        if (stockCode === 'N') {
          if (qtdMaterial < qtdLimit && qtdMaterial !== dispPE) {
            return {
              Qtd: 0,
              Error: 'A quantidade mínima deve ser maior ou igual à ' + qtdLimit
            };
          }
          //Regra para travar quantidade de material acima do estoque disponivel
          //TO:DO Ativar para a proxima versão
          var QtdOrg = material.QtdMaterialOrg == undefined ? 0 : material.QtdMaterialOrg;
          var CheckStockATC = material.StockATC == undefined ? true : false;

          var CheckisPE = material.isPE == undefined ? true : false;

          if (Editavel && qtdMaterial != QtdOrg && dispPE != 0 && qtdMaterial > dispPE && CheckisPE) {
            //if (Editavel && qtdMaterial > material.QtdMaterialOrg) {
            if (dispPE != 0 && qtdMaterial > dispPE) {
              return {
                Qtd: 0,
                Error: 'A quantidade máxima deve ser menor ou igual à ' + dispPE
              };
            }
            if (dispPE == 0) {
              return {
                Qtd: 0,
                Error: 'Não há estoque disponível'
              };
            }
          }

          if (Editavel && qtdMaterial != QtdOrg && dispPE != 0 && qtdMaterial > dispPE && material.isPE) {
            //if (Editavel && qtdMaterial > material.QtdMaterialOrg) {
            if (dispPE != 0 && qtdMaterial > material.QtdMaterialOld + dispPE) {
              return {
                Qtd: 0,
                Error: 'A quantidade máxima deve ser menor ou igual à ' + (material.QtdMaterial + dispPE)
              };
            }
            if (dispPE == 0) {
              return {
                Qtd: 0,
                Error: 'Não há estoque disponível'
              };
            }
          } else if (Editavel && material.isPE && qtdMaterial != material.QtdMaterialOld && qtdMaterial > QtdOrg && qtdMaterial > dispPE && dispPE == 0) {
            return {
              Qtd: 0,
              Error: 'Não há estoque disponível'
            };
          }
          //TO:DO regra de estoque disponivel PE

          else if (Editavel && qtdMaterial != QtdOrg && QtdOrg != 0 && material.StockATC && !material.isPE) {
              var dispCHECK = material.QtdMaterialOld + material.StockATC.Qtde;

              if (qtdMaterial > dispCHECK) {
                return {
                  Qtd: 0,
                  Error: 'Não há estoque ATC disponível'
                };
              }
            } else if (Editavel && qtdMaterial != QtdOrg && material.DateAtcDelv && !CheckStockATC && qtdMaterial > material.QtdMaterialOld && !material.isPE) {
              return {
                Qtd: 0,
                Error: 'Não há estoque ATC disponível'
              };
            } else if (Editavel && QtdOrg == 0 && material.DateAtcDelv && CheckStockATC && qtdMaterial > material.StockMATC && !material.isPE) {
              return {
                Qtd: 0,
                Error: 'Não há estoque ATC disponível'
              };
            } else if (Editavel && material.DateAtcDelv && CheckStockATC && qtdMaterial > material.StockMATC && !material.isPE) {
              return {
                Qtd: 0,
                Error: 'Não há estoque ATC disponível'
              };
            }
        } else if (qtdMaterial > qtdLimit && qtdMaterial !== dispPE) {
          return {
            Qtd: 0,
            Error: 'A quantidade máxima deve ser menor ou igual à ' + qtdLimit
          };
        }
      }

      if (limit.TpStockCode[stockCode] && limit.TpStockCode[stockCode].FreeShipping) {
        if (limit.TpStockCode[stockCode].FreeShipping.PaymentTerms.indexOf(deadline) > -1) {
          if (qtdMaterial > limit.TpStockCode[stockCode].FreeShipping[unid]) {
            // console.log("fr3f4534 qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]", qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]);
            return {
              Error: 'Venda gratuita acima da metragem permitida.'
            };
          } else {
            // console.log("fr3f4534 NÃO TEM qtdMaterial MAIOR", qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]);
          }
        } else {
            // console.log("fr3f4534 NÃO TEM deadline", deadline);
          }
      } else {
          // console.log("fr3f4534 NÃO TEM FreeShipping", limit, limit.TpStockCode[stockCode]);
        }

      return {
        Qtd: qtdMaterial
      };
    }

    function checkStockLimits(limit, qtdMaterial, stockCode, unid, deadline, isProfileFutureSale) {
      if (!limit) {
        return {
          Qtd: 0,
          Error: 'Parametro não pode ser nulo'
        };
      }

      if (_.has(limit.TpStockCode, stockCode)) {
        var qtdLimit = +limit.TpStockCode[stockCode][unid] || 0;
        if (stockCode === 'N') {
          if (qtdMaterial < qtdLimit) {
            return {
              Qtd: 0,
              Error: 'A quantidade mínima deve ser maior ou igual à ' + qtdLimit
            };
          }
          if (qtdMaterial < qtdLimit) {
            return {
              Qtd: 0,
              Error: 'A quantidade mínima deve ser maior ou igual à ' + qtdLimit
            };
          }
        } else if (qtdMaterial > qtdLimit) {
          return {
            Qtd: 0,
            Error: 'A quantidade máxima deve ser menor ou igual à ' + qtdLimit
          };
        }
      }

      if (limit.TpStockCode[stockCode] && limit.TpStockCode[stockCode].FreeShipping) {
        if (limit.TpStockCode[stockCode].FreeShipping.PaymentTerms.indexOf(deadline) > -1) {
          if (qtdMaterial > limit.TpStockCode[stockCode].FreeShipping[unid]) {
            // console.log("fr3f4534 qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]", qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]);
            return {
              Error: 'Venda gratuita acima da metragem permitida.'
            };
          } else {
            // console.log("fr3f4534 NÃO TEM qtdMaterial MAIOR", qtdMaterial, limit.TpStockCode[stockCode].FreeShipping[unid]);
          }
        } else {
            // console.log("fr3f4534 NÃO TEM deadline", deadline);
          }
      } else {
          // console.log("fr3f4534 NÃO TEM FreeShipping", limit, limit.TpStockCode[stockCode]);
        }

      return {
        Qtd: qtdMaterial
      };
    }

    function checkStockCentro(MaterialCode, Centro, qtdProntEntreg, isEdit, DateAtcDelv) {

      if (!isEdit) {
        if (DateAtcDelv == undefined) {
          if (qtdProntEntreg == 0) {
            return {
              Error: '\u2022 ' + MaterialCode + ': Não há estoque disponível no centro (' + Centro + ').'
            };
          }
        }
      }

      return {
        Error: false
      };
    }

    //TO:DO considerar estoque reservado
    function checkMultiple(qtdMaterial, qtdEstApos, PayCond, MaterialCode, qtdProntEntreg, qtdeMultiplo, corteMin, TpStockCode, Abgru, isEdit, qtdMaterialOrg, DateAtcDelv, StockATC, QtdMaterialOld, isPE) {

      var isZong = void 0;
      var queimaQtdEstApos = void 0;
      var queimaQtdPront = void 0;
      var pagtoAntecipado = void 0;
      var isMultiplo;
      var corteNum = 0;
      var MultiplePassTotal = 0;
      var isFTSlimpaEstoque = qtdMaterialOrg != undefined ? true : false;
      corteNum = parseInt(corteMin);

      //TO:DO check new item in edit order, check StockATC item object
      if (StockATC == undefined) {
        if (!isPE) {
          MultiplePassTotal = qtdEstApos;
        }
      } else {
        MultiplePassTotal = isPE ? qtdProntEntreg + QtdMaterialOld : StockATC.Qtde + QtdMaterialOld;
      }

      if (qtdeMultiplo && qtdMaterial) {

        isMultiplo = qtdMaterial % qtdeMultiplo == 0;

        // validação pagamento antecipado
        if (PayCond == "A001") {
          pagtoAntecipado = true;
        } else if (PayCond != "A001") {
          pagtoAntecipado = false;
        }

        //   validaao isZong (código do material começa com z)
        if (MaterialCode.charAt(0) == "Z") {
          isZong = true;
        } else {
          isZong = false;
        }

        if (qtdMaterial == qtdEstApos) {
          if (corteNum == 0) {
            queimaQtdEstApos = true;
          } else {
            queimaQtdEstApos = false;
          }
        } else {
          queimaQtdEstApos = false;
        }

        if (qtdMaterial == qtdProntEntreg) {
          if (corteNum == 0) {
            queimaQtdPront = true;
          } else {
            queimaQtdPront = false;
          }
        } else {
          queimaQtdPront = false;
        }

        if (Abgru == '') {
          var tipoEstoque = TpStockCode.toLowerCase();
          var pilotagem = tipoEstoque.charAt(0) == "p";
          var cartela = tipoEstoque.charAt(0) == "c";

          if (qtdeMultiplo) {

            if (!queimaQtdEstApos) {

              if (!queimaQtdPront) {

                if (isZong) {

                  if (MultiplePassTotal != qtdMaterial) {

                    if (qtdeMultiplo > 0 && !isMultiplo) {
                      return {
                        Error: '\u2022 A quantidade do material ' + MaterialCode + ' ' + 'deve ser multiplo de ' + ' ' + qtdeMultiplo + '<br />'
                      };
                    } else {
                      return {
                        Qtd: qtdMaterial
                      };
                    }
                  } else {
                    return {
                      Qtd: qtdMaterial
                    };
                  }
                }

                if (!pilotagem && !cartela) {
                  if (!isZong && corteNum > 0) {
                    if (qtdMaterial < corteNum) {
                      console.log("===== REgra de multiplo/minimo");
                      console.log(qtdMaterial);
                      console.log(qtdProntEntreg);
                      if (isEdit) {
                        if (qtdMaterial == qtdProntEntreg || qtdMaterial == qtdMaterialOrg) {
                          return {
                            Qtd: qtdMaterial
                          };
                        } else {
                          return {
                            Error: '\u2022 A quantidade do material ' + MaterialCode + ' ' + 'deve ser igual ou maior ao corte mínimo ' + ' ' + parseInt(corteMin) + '<br />'
                          };
                        }
                      } else {
                        if (qtdMaterial == qtdProntEntreg) {
                          return {
                            Qtd: qtdMaterial
                          };
                        } else {
                          return {
                            Error: '\u2022 A quantidade do material ' + MaterialCode + ' ' + 'deve ser igual ou maior ao corte mínimo ' + ' ' + parseInt(corteMin) + '<br />'
                          };
                        }
                      }
                    } else {
                      return {
                        Qtd: qtdMaterial
                      };
                    }
                  } else {
                    if (isFTSlimpaEstoque) {
                      if (MultiplePassTotal != qtdMaterial && DateAtcDelv && qtdMaterial != QtdMaterialOld) {
                        if (qtdeMultiplo > 0 && !isMultiplo) {
                          return {
                            Error: '\u2022 Material' + ' ' + MaterialCode + ' ' + 'não sujeito a corte, a quantidade deve ser multiplo de ' + ' ' + qtdeMultiplo + '<br />'
                          };
                        } else {
                          return {
                            Qtd: qtdMaterial
                          };
                        }
                      } else {
                        if (isPE) {
                          if (MultiplePassTotal == qtdMaterial) {
                            return {
                              Qtd: qtdMaterial
                            };
                          } else {
                            if (qtdeMultiplo > 0 && !isMultiplo) {
                              return {
                                Error: '\u2022 Material' + ' ' + MaterialCode + ' ' + 'não sujeito a corte, a quantidade deve ser multiplo de ' + ' ' + qtdeMultiplo + '<br />'
                              };
                            } else {
                              return {
                                Qtd: qtdMaterial
                              };
                            }
                          }
                        }
                      }
                    } else {
                      if (qtdeMultiplo > 0 && !isMultiplo) {
                        return {
                          Error: '\u2022 Material' + ' ' + MaterialCode + ' ' + 'não sujeito a corte, a quantidade deve ser multiplo de ' + ' ' + qtdeMultiplo + '<br />'
                        };
                      } else {
                        return {
                          Qtd: qtdMaterial
                        };
                      }
                    }
                  }
                } else {
                  return {
                    Qtd: qtdMaterial
                  };
                }
              } else {
                return {
                  Qtd: qtdMaterial
                };
              }
            } else {
              return {
                Qtd: qtdMaterial
              };
            }
          }
        } else {
          return {
            Qtd: qtdMaterial
          };
        }
      } else {
        return {
          Qtd: qtdMaterial
        };
      }
    }

    function checkStockDate(DateOutVend) {
      if (DateOutVend) {
        return false;
      }

      var numbers = DateOutVend.match(/\d+/g);
      var date = new Date(numbers[2], numbers[0] - 1, numbers[1]);

      return date < _.now() ? {
        Qtd: 0,
        Error: 'Venda fechada.'
      } : {};
    }

    function checkStockType(qtd, items, type) {
      var test = type === 'C' ? _(items).every(function (material) {
        return material.TpStockCode === type;
      }) : _(items).every(function (material) {
        return material.TpStockCode !== 'C';
      });

      return test ? {
        Qtd: qtd
      } : {
        Qtd: 0,
        Error: 'Você inseriu um produto incompatível com o estoque do carrinho.' + ' Para comprar produtos deste estoque é necessário limpar seu carrinho.'
      };
    }

    function overPrice(tprice, cprice, ovp) {
      var parsedPrice = parseFloat(tprice, 10);

      return parsedPrice + parseFloat(parsedPrice * ovp / 100, 10) < parseFloat(cprice, 10) ? {
        Error: 'Valor maior que o permitido.'
      } : {};
    }
  }
})();