'use strict';

(function () {
  'use strict';

  angular.module('app').directive('checkEqual', checkEqual);

  function checkEqual() {
    return {
      scope: {
        model: '=',
        click: '&',
        type: '@'
      },
      replace: true,
      link: link,
      templateUrl: 'app/clients/directives/checkEqual.html'
    };

    function link(scope, element) {
      scope.name = 'check' + scope.type;
      var box = element.parent().parent();

      scope.setEqual = function () {
        if (scope.model) {
          box.removeClass('active');
        }
        scope.click({ type: scope.type, flag: scope.model });
      };
    }
  }
})();