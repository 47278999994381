'use strict';

(function () {
  'use strict';

  angular.module('app').directive('sortButton', sortButton);

  function sortButton() {
    return {
      replace: true,
      transclude: true,
      link: link,
      templateUrl: 'app/directives/sortButton.html'
    };

    function link(scope, element, attrs) {
      var tag = element.find('span');
      var ctrl = scope.$ctrl || scope.vm;
      var page = ctrl.page,
          submitForm = ctrl.submitForm;

      var sortFun = submitForm.bind(ctrl);
      var sortQuery = attrs.collumn;
      var sortOptions = {
        0: { sort: ' ', css: 'icon-tag-down disabled' },
        1: { sort: sortQuery, css: 'icon-tag-up' },
        2: { sort: '-' + sortQuery, css: 'icon-tag-down' }
      };

      scope.$ctrl = scope.$ctrl || scope.vm;

      var dir = 0;

      scope.$watch('$ctrl.page.sort', function (sort) {
        dir = 0;
        for (var option in sortOptions) {
          if (sort && sortOptions[option].sort === sort) {
            dir = parseInt(option, 10);
          }
        }
        tag.attr('class', 'icon ' + sortOptions[dir].css);
      });

      element.bind('click', function () {
        dir += 1;

        if (dir > 2) {
          dir = 0;
        }

        tag.attr('class', 'icon ' + sortOptions[dir].css);
        page.sort = sortOptions[dir].sort;

        sortFun();
      });
    }
  }
})();