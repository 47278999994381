'use strict';

(function () {
  'use strict';

  var favoriteButton = function favoriteButton() {
    return {
      scope: {},
      controller: favoriteButtonController,
      replace: true,
      controllerAs: '$ctrl',
      bindToController: {
        model: '<'
      },
      templateUrl: 'app/books/directives/favoriteButton.html'
    };

    function favoriteButtonController(dataService, localService) {
      var kart = localService.getData('kart');

      this.toggleFav = function () {
        var action = void 0;
        var data = {
          CodeCli: kart.Master.CodeCli,
          MaterialCode: this.model.MaterialCode || this.model.Master.MaterialCode
        };

        this.model.Favorito = !this.model.Favorito;
        action = this.model.Favorito ? 'Add' : 'Remove';
        return dataService.postData(action + 'FavoriteMaterial', data).then(function () {
          return null;
        }, localService.errorHandler);
      };
    }
  };

  angular.module('app').directive('favoriteButton', favoriteButton);
})();